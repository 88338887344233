import { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";

const FacturaProveedorContext = createContext();

export const useFacturasProveedores = () => {
  const context = useContext(FacturaProveedorContext);
  if (!context) throw new Error("FacturaProveedor Provider is missing");
  return context;
};

export const FacturaProveedorProvider = ({ children }) => {
  const [facturasProveedores, setFacturasProveedores] = useState([]);

  const baseURL = "https://server-pos-vkb9.onrender.com/api/facturas-proveedores"; // Establece la URL base de tu API

  const fetchFacturasProveedores = async () => {
    try {
      const res = await axios.get(baseURL);
      setFacturasProveedores(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchFacturasProveedores();
  }, []);

  const deleteFacturaProveedor = async (id) => {
    try {
      const res = await axios.delete(`${baseURL}/${id}`);
      if (res.status === 204) {
        setFacturasProveedores(facturasProveedores.filter((factura) => factura._id !== id));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const createFacturaProveedor = async (factura) => {
    try {
      const res = await axios.post(baseURL, factura);
      setFacturasProveedores([...facturasProveedores, res.data]);
    } catch (error) {
      console.error(error);
    }
  };

  const getFacturaProveedor = async (id) => {
    try {
      const res = await axios.get(`${baseURL}/${id}`);
      return res.data;
    } catch (error) {
      console.error(error);
    }
  };

  const updateFacturaProveedor = async (id, factura) => {
    try {
      const res = await axios.put(`${baseURL}/${id}`, factura);
      setFacturasProveedores(facturasProveedores.map((factura) => (factura._id === id ? res.data : factura)));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <FacturaProveedorContext.Provider
      value={{ facturasProveedores, deleteFacturaProveedor, createFacturaProveedor, getFacturaProveedor, updateFacturaProveedor }}
    >
      {children}
    </FacturaProveedorContext.Provider>
  );
};