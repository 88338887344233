import React from 'react';
import './DropdownMenu.css'; // Asegúrate de tener los estilos CSS
import { Link } from "react-router-dom";
import { LuLayoutList } from "react-icons/lu";
import { LiaMoneyBillWaveAltSolid } from "react-icons/lia";
import { FaReceipt } from "react-icons/fa";
import { TbToolsKitchen2 } from "react-icons/tb";
import { FaPerson } from "react-icons/fa6";
import { MdAnalytics } from "react-icons/md";
import { FaTruck } from "react-icons/fa";


export function DropdownMenu({ isOpen, onClose, style }) {
    const handleItemClick = () => {
      onClose(); // Cierra el menú cuando se hace clic en un ítem
    };
    
    return (
      <div className="dropdown-menu" style={{ ...style, display: isOpen ? 'block' : 'none' }}>
        <ul className='tabla-menu md:ml-0'>


          <li onClick={handleItemClick}><button className="options-button">
          <Link className='container-options' to="/Services">
            <LiaMoneyBillWaveAltSolid className='icono-header' />
            <h1 className='ml-5 text-white'>Facturar</h1>
          </Link>
        </button></li>


        <li onClick={handleItemClick}><button className="options-button">
          <Link className='container-options'  to="/Inventario">
            <LuLayoutList className='icono-header' />
            <h1 className='ml-5 text-white'>Inventario</h1>
          </Link>
        </button></li>

        <li onClick={handleItemClick}><button className="options-button">
          <Link  className='container-options' to="/Proveedores">
            <FaTruck className='icono-header' />
            <h1 className='ml-5 text-white'>Proveedores</h1>
          </Link>
        </button></li>


          <li onClick={handleItemClick}><button className="options-button">
          <Link className='container-options' to="/Facturas">
            <FaReceipt className='icono-header' />
            <h1 className='ml-5 text-white'>Facturas</h1>
          </Link>
        </button></li>

        <li onClick={handleItemClick}><button className="options-button">
            <Link className='container-options' to="/Cocina">
                <TbToolsKitchen2  className='icono-header' />
                <h1 className='ml-5 text-white'>Cocina</h1>
            </Link>
            </button>
        </li>

        <li onClick={handleItemClick}><button className="options-button" >
          <Link className='container-options' to="/Nomina">
            <FaPerson className='icono-header' />
            <h1 className='ml-5 text-white'>Nómina</h1>
          </Link>
        </button></li>

        <li onClick={handleItemClick}><button className="options-button flex" >
          <Link className='container-options' to="/Analitica">
            <MdAnalytics className='icono-header' />
            <h1 className='ml-5 text-white'>Analitica</h1>
          </Link>
        </button></li>

        <li onClick={handleItemClick}><button className="options-button flex" >
          <Link className='container-options' to="/Metricas">
            <MdAnalytics className='icono-header' />
            <h1 className='ml-5 text-white'>Metricas</h1>
          </Link>
        </button></li>


        {/*
        <li onClick={handleItemClick}><button className="options-button" >
          <Link className='container-options' to="/Voice">
            <MdAnalytics className='icono-header' />
            <h1 className='ml-5 text-white'>Analitica</h1>
          </Link>
        </button></li>
        */}

          {/* Agrega más opciones según sea necesario */}
        </ul>
         {/* Cierra el menú cuando se hace clic en el botón de cerrar */}
      </div>
    );
  }