import React, { useState, useEffect } from 'react';
import './CocinaList.scss';
import { CiCircleCheck } from "react-icons/ci";
import { useCocina } from "../../context/cocinaContext";
import Aviso from './Aviso';

function CartaCocina({ cocina }) {
    const [realizado, setRealizado] = useState(cocina.estado === 'realizado');
    const { updateCocina } = useCocina();

    useEffect(() => {
        if (realizado !== (cocina.estado === 'realizado')) {
            updateCocina(cocina._id, { estado: realizado ? 'realizado' : 'pendiente' });
        }
    }, [realizado, cocina._id, cocina.estado, updateCocina]); // Agregando todas las dependencias necesarias
    
    

    const handleClick = (event) => {
        event.stopPropagation();
        event.preventDefault(); // Prevent default to stop any form submission
        setRealizado(prev => !prev); // Use functional update to ensure we always toggle from the current state
    };

    return (
        <div className={`cocina-item ${realizado ? 'realizado' : ''}`}>
            <div className=''>
                <h5 className='hora-cocina'>Hora: {new Date(cocina.createdAt).toLocaleString('es-ES', { hour: '2-digit', minute: '2-digit' })} </h5>
                <h5 className='cliente-cocina'>Cliente: {cocina.cliente} </h5>
                <h5 className='cliente-cocina'>Sede: {cocina.sede} </h5>
                <h5 className='mesa-cocina'>MESA: <span className='cocina-mesa ml-4'>{cocina.mesa}</span> </h5>
                <h5 className='cliente-cocina-dom'>{cocina.domicilio} </h5>
                <div className="productos-container-coc">
                    {cocina.productos.map((producto, index) => (
                        <div key={index} className="producto-cocina flex">
                            <p className="nombre-cocina">
                                {producto.nombre}
                                <hr/>
                            </p>
                            <p className="cocina-cantidad">{producto.cantidad}</p>
                        </div>
                    ))}
                    <p className='mensaje-coc text-center'>{cocina.observacion}</p>
                </div>
                <div className='boton-hecho'>
                    <CiCircleCheck className='boton-realizado' onClick={handleClick} />
                </div>
            </div>
        </div>
    );
}




export function CocinaList({ cocina }) {
    const [prevCount, setPrevCount] = useState(cocina.length);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        // Comprobar si la longitud del arreglo ha cambiado (nuevos pedidos)
        if (cocina.length > prevCount) {
            setShowModal(true);  // Mostrar el modal cuando hay una nueva orden
            setPrevCount(cocina.length);
        }
    }, [cocina.length, prevCount]);

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <div className="cocina-list-container">
            <Aviso isOpen={showModal} onClose={handleCloseModal} />
            <div className="cocina-list grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
                {cocina.map(cocinaItem => (
                    <CartaCocina key={cocinaItem._id} cocina={cocinaItem} />
                ))}
            </div>
        </div>
    );
}