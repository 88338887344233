import { usePosts } from "../../context/postContext";
import { useAuth } from "../../context/AuthContext";
import './Inventario.scss';
import { PostInventario } from "../../components/PostInventario/PostInventario";
import React, { useState } from 'react';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { ModalComponent } from "../../components/Modal/ModalCreateItem";
import { FaBottleWater, FaBorderAll, FaPerson  } from "react-icons/fa6";
import { LuCandy } from "react-icons/lu";
import { GiHamburger } from 'react-icons/gi';
import { TbLayoutGridAdd } from "react-icons/tb";

export function Inventario({ selectedColor }) {
  const { posts } = usePosts();
  const [isModalOpenProducto, setIsModalOpenProducto] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [setSelectedProduct] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const { user } = useAuth();
  const filteredPosts = posts.filter(post => post.sede === user.sede);

  const renderPost = () => {
    const filteredSearchPosts = filteredPosts.filter(post => post.title.toLowerCase().includes(searchTerm.toLowerCase()));
      
    return (
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-6 gap-6">
        {filteredSearchPosts.map((post) => (
          <PostInventario
            key={post._id}
            post={post}
            onEdit={() => handleEditProduct(post)}
            selectedColor={selectedColor}
            isSelected={selectedProducts.some((selectedProduct) => selectedProduct._id === post._id)}
          />
        ))}
      </div>
    );
  };

  const [currentCategory, setCurrentCategory] = useState('Todos');
  const categories = ['Todos', 'Comida', 'Bebidas', 'Golosinas', 'Cliente-Especial'];

  const renderCategoryIcon = (category) => {
    switch (category) {
      case 'Todos':
        return <FaBorderAll />;
      case 'Comida':
        return <GiHamburger />;
      case 'Bebidas':
        return <FaBottleWater />;
      case 'Golosinas':
        return <LuCandy />;
      case 'Cliente-Especial':
        return <FaPerson />;
      default:
        return null;
    }
  };

  const openModalProducto = () => {
    setIsModalOpenProducto(true);
  };

  const closeModalProducto = () => {
    setIsModalOpenProducto(false);
  };

  const handleEditProduct = (product) => {
    const isSelected = selectedProducts.some((selectedProduct) => selectedProduct._id === product._id);
  
    if (isSelected) {
      // Si ya está seleccionado, deselecciónalo
      setSelectedProducts(selectedProducts.filter((selectedProduct) => selectedProduct._id !== product._id));
      setSelectedProduct(null); // Desselecciona el producto
    } else {
      // Si no está seleccionado, agrégalo a la lista de seleccionados con cantidad 1
      const updatedProduct = { ...product, quantity: 1 };
      setSelectedProducts([...selectedProducts, updatedProduct]);
      setSelectedProduct(updatedProduct); // Establece el producto seleccionado
    }
  };

  const handleCategoryChange = (category) => {
    setCurrentCategory(category);
  };

  const handleSearchChange = (event) => {
    // Lógica para manejar el cambio en la búsqueda
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
    // Puedes hacer algo con searchTerm aquí, como actualizar el estado
  };

  return (
    <div className="main-inventario">
      <header>
      <div className="todo-superior">
        <div className="flex justify-between w-full parte-superior">
          {/* Barra de búsqueda */}
          <div className="buscador-inventario flex">
            <input
              type="text"
              placeholder="Buscar por título..."
              value={searchTerm}
              onChange={handleSearchChange}
              className=""
            />
          </div>
          <div className="categorias-tabs hidden lg:block">
            {categories.map((category) => (
              <button
                key={category}
                onClick={() => handleCategoryChange(category)}
                className={`categoria-tab ${currentCategory === category ? 'active' : ''}`}
                style={{
                  backgroundColor: currentCategory === category ? selectedColor : 'initial',
                  color: currentCategory === category ? 'white' : selectedColor,
                  borderColor: selectedColor,
                }}
              >
                {renderCategoryIcon(category)}
              </button>
            ))}
          </div>
          <div>
            <TbLayoutGridAdd onClick={openModalProducto} className="boton-nuevo-producto" style={{color: selectedColor}}>
              Nuevo
            </TbLayoutGridAdd>
          </div>  
          </div>      
        </div>
      </header>
      <div className="flex capsula-completa md:mt-12">
        <div className="productos-presentacion-carta">
          <div className="capsula-render">
            {renderPost()}
          </div>
        </div>
      </div>
      <div className={{backgroundColor: selectedColor}}>
        <ModalComponent
          isOpen={isModalOpenProducto}
          onRequestClose={closeModalProducto}
        />
      </div>
      <div></div>
    </div>
  );
}
