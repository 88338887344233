import React from 'react';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { ProveedoresCard } from "../../components/Proveedores/ProveedoresCard";
import './Proveedores.scss'



export function Proveedores( {selectedColor} ) {
  
  return (
  <div className="main-proveedores">
            <ProveedoresCard className='capsula-render-pro' selectedColor={selectedColor}/>
  </div>
  );
}