import React, { useEffect, useState } from 'react';
import './Analitica.scss';
import { useFacturas } from '../../context/facturaContext';
import { useFacturasProveedores } from '../../context/facturasProveedoresContext';
import { useFacturasEmpleados } from '../../context/facturasEmpleadoContext';
import { useUsuarios } from '../../context/usuarioContext';
import { FaRegUser } from "react-icons/fa";
import { useClientes } from '../../context/clienteContext';

export function Analitica({ selectedColor }) {
  const { facturas } = useFacturas();
  const { usuarios } = useUsuarios();
  const { clientes } = useClientes();
  const [totalVentas, setTotalVentas] = useState(0);
  const [totalEfectivo, setTotalEfectivo] = useState(0);
  const [totalTransferencia, setTotalTransferencia] = useState(0);
  const [totalFiado, setTotalFiado] = useState(0);
  const [totalFamilia, setTotalFamilia] = useState(0);
  const { facturasProveedores } = useFacturasProveedores();
  const [totalProveedores, setTotalProveedores] = useState(0);
  const { facturasEmpleados } = useFacturasEmpleados();
  const [totalNomina, setTotalNomina] = useState(0);
  const [base, setBase] = useState(0); // Inicialmente se establecerá en 0
  const [selectedEmpleado, setSelectedEmpleado] = useState('');
  const [totalDeudas, setTotalDeudas] = useState(0);

  useEffect(() => {
    // Obtener el valor de la base de la cafetería
    if (usuarios.length > 0) {
      const cafeteria = usuarios[0].sedes.find(sede => sede.nombre === "Cafeteria");
      if (cafeteria) {
        setBase(cafeteria.base);
      } else {
        console.log("No se encontró la sede de la cafetería.");
      }
    }
  }, [usuarios]);
  

  useEffect(() => {
    // Filtrar las facturas del día actual y del empleado seleccionado
    const today = new Date();
    const currentDay = today.getDate();
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();
  
    const filteredFacturas = facturas.filter(factura => {
      const fechaFactura = new Date(factura.createdAt);
      return (
        fechaFactura.getDate() === currentDay &&
        fechaFactura.getMonth() === currentMonth &&
        fechaFactura.getFullYear() === currentYear &&
        factura.vendedor === selectedEmpleado // Filtrar por el empleado seleccionado
      );
    });
  
    const totals = filteredFacturas.reduce(
      (acc, factura) => {
        acc.totalVentas += factura.totalFactura;

        factura.metodosPago.forEach(metodo => {
          switch (metodo.metodo) {
            case 'efectivo':
              acc.totalEfectivo += metodo.cantidad;
              break;
            case 'transferencia':
              acc.totalTransferencia += metodo.cantidad;
              break;
            case 'fiado':
              acc.totalFiado += metodo.cantidad;
              break;
            case 'familia':
              acc.totalFamilia += metodo.cantidad;
              break;
            default:
              break;
          }
        });

        return acc;
      },
      {
        totalVentas: 0,
        totalEfectivo: 0,
        totalTransferencia: 0,
        totalFiado: 0,
        totalFamilia: 0,
      }
    );
  
    setTotalVentas(totals.totalVentas);
    setTotalEfectivo(totals.totalEfectivo);
    setTotalTransferencia(totals.totalTransferencia);
    setTotalFiado(totals.totalFiado);
    setTotalFamilia(totals.totalFamilia);
  
  }, [facturas, selectedEmpleado]); // Agrega selectedEmpleado al arreglo de dependencias
  

  useEffect(() => {
    // Filtrar las facturas de nómina del día actual y del empleado seleccionado
    const today = new Date();
    const currentDay = today.getDate();
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();
  
    const filteredFacturasEmpleados = facturasEmpleados.filter(facturaEmpleado => {
      const fechaFactura = new Date(facturaEmpleado.createdAt);
      return (
        fechaFactura.getDate() === currentDay &&
        fechaFactura.getMonth() === currentMonth &&
        fechaFactura.getFullYear() === currentYear &&
        facturaEmpleado.vendedor === selectedEmpleado // Filtrar por el empleado seleccionado
      );
    });
  
    const totalNominaHoy = filteredFacturasEmpleados.reduce((total, facturaEmpleado) => {
      return total + facturaEmpleado.totalGanado;
    }, 0);
    
    setTotalNomina(totalNominaHoy);
  }, [facturasEmpleados, selectedEmpleado]);


  useEffect(() => {
    // Filtrar las facturas de proveedores del día actual y del empleado seleccionado
    const today = new Date();
    const currentDay = today.getDate();
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();
  
    const filteredFacturasProveedores = facturasProveedores.filter(facturaProv => {
      const fechaFactura = new Date(facturaProv.createdAt);
      return (
        fechaFactura.getDate() === currentDay &&
        fechaFactura.getMonth() === currentMonth &&
        fechaFactura.getFullYear() === currentYear &&
        facturaProv.vendedor === selectedEmpleado // Filtrar por el empleado seleccionado
      );
    });
  
    const totalProveedoresHoy = filteredFacturasProveedores.reduce((total, facturaProv) => {
      return total + facturaProv.totalFactura;
    }, 0);
    
    setTotalProveedores(totalProveedoresHoy);
  }, [facturasProveedores, selectedEmpleado]);

  let gananciasCalculadas;
  let baseCalculada = base + totalEfectivo - (totalProveedores + totalNomina);

if (baseCalculada >= 1000000) {
  gananciasCalculadas = baseCalculada - 1000000;
  baseCalculada = 1000000;
} else {
  gananciasCalculadas = 0;
}

// Calcular la suma de todas las deudas de los clientes
useEffect(() => {
  const total = clientes.reduce((sum, cliente) => sum + cliente.deuda, 0);
  setTotalDeudas(total);
}, [clientes]);



  return (
    <div className="w-full  main-analitica py-8 w-full">
      <div className="w-full lg:w-full px-4">
        <div className='header-analitica'>
          <select className='select-usuario' value={selectedEmpleado} onChange={(e) => setSelectedEmpleado(e.target.value)}>
            <option value="">Selecciona un empleado</option>
            {/* Renderizar las opciones dentro del select */}
            {usuarios.length > 0 && usuarios[0].empleados.map(empleado => (
              <option key={empleado._id} value={empleado.usuario}>{empleado.usuario}</option>
            ))}
          </select>
        </div>

        <div className="flex flex-wrap justify-center">
          <div className="w-full lg:w-1/2 xl:w-2/6 mb-8 grande">
            <div className=" p-6 total-vendido">
              <div className="text-center ventas-efectivo">
                <p className='totales'>{totalEfectivo}</p>
                <p className="text-lg">Efectivo</p>
              </div>
              <div className="text-center ventas-efectivo">
                <p className='totales'>{totalTransferencia}</p>
                <p className="text-lg">Transferencias</p>
              </div>
              <div className="text-center ventas-efectivo">
                <p className='totales'>{totalFiado}</p>
                <p className="text-lg">Fiado</p>
              </div>
              <div className="text-center ventas-efectivo">
                <p className='totales'>{totalFamilia}</p>
                <p className="text-lg">Familia</p>
              </div>
              <div className='ventas-totales2'>
                <h1 className="text-center text-lg">VENTAS TOTALES</h1>
                <div className="text-center text-lg font-semibold">
                  <p className='totales'>{totalVentas}</p>
                </div>
              </div>
              <div className='ventas-totales2'>
                <h1 className="text-center text-lg ">BASE</h1>
                <div className="text-center text-lg  font-semibold">
                  <p className='totales'>{baseCalculada}</p>
                </div>
              </div>

              <div className='ventas-totales2'>
                <h1 className="text-center text-lg">GANANCIAS</h1>
                <div className="text-center text-lg  font-semibold">
                  <p className='totales'>{gananciasCalculadas}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/2 xl:w-2/6 px-4">
            <div className="rounded-lg carta-adentro mb-5">
              <div className="text-center capsula-lista-prov">
                {facturasProveedores.filter(facturaProv => {
                  const fechaFactura = new Date(facturaProv.createdAt);
                  const today = new Date();
                  return fechaFactura.getDate() === today.getDate() &&
                        fechaFactura.getMonth() === today.getMonth() &&
                        fechaFactura.getFullYear() === today.getFullYear() &&
                        facturaProv.vendedor === selectedEmpleado;
                }).map((facturaProv) => (
                  <div className='capsula-prov' key={facturaProv.id}>
                    <p>{facturaProv.nombreProveedor}</p>
                    <p>{facturaProv.totalFactura}</p>
                  </div>
                ))}
              </div>
              <div className="text-center mt-4">
                <div className='gastos'>
                  <p className="text-lg">TOTAL PROVEEDORES</p>
                  <p className='total-fiados'>{totalProveedores}</p>
                </div>
              </div>
            </div>
            <div className="rounded-lg carta-adentro">
              <div className="text-center capsula-lista-prov">
                {facturasEmpleados.filter(facturaEmpleado => {
                  const fechaFactura = new Date(facturaEmpleado.createdAt);
                  const today = new Date();
                  return fechaFactura.getDate() === today.getDate() &&
                        fechaFactura.getMonth() === today.getMonth() &&
                        fechaFactura.getFullYear() === today.getFullYear() &&
                        facturaEmpleado.vendedor === selectedEmpleado;
                }).map((facturaEmpleado) => (
                  <div className='capsula-prov' key={facturaEmpleado._id}>
                    <p>{facturaEmpleado.nombre}</p>
                    <p> {facturaEmpleado.totalGanado}</p>
                  </div>
                ))}
              </div>
              <div className="text-center mt-8">
                <div className='gastos'>
                  <p className="text-lg">TOTAL NÓMINA</p>
                  <p className='total-fiados'>{totalNomina}</p>
                </div>
              </div>
            </div>
          </div>


            <div className='w-full capsula-fiados lg:w-1/2 xl:w-2/6 mb-8 mt-7'>
              <div className="fiados-carta">
                {clientes.map(cliente => (
                  <div className='cap-fiados w-full' key={cliente.id}>
                    <div className='w-1/5 asd'>
                      <FaRegUser className='text-4xl text-blue-400' />
                    </div>
                    <div className='w-2/5 text-center asd'>
                      <p className='fiado-text-n'>{cliente.nombre}</p>
                    </div>
                    <div className='w-2/5 asd'>
                      <p className='fiado-text-t'>{cliente.deuda}</p>
                    </div>
                  </div>
                ))}
            </div>
            <div className='sumatoria-fiados'>
              <p>TOTAL EN FIADOS</p>
              <p className='total-fiados'>{totalDeudas}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}