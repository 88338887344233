import React from 'react';
import './Nomina.scss';
import { NominaBgComponent } from '../../components/NominaBG/NominaBg';
import { NominaSmComponent } from '../../components/NominaBG/NominaSm';



export function Nomina({ selectedColor }) {
  
  return (
    <>
    <div className="hidden lg:block w-full h-full main-nomina2">
    <NominaBgComponent selectedColor={selectedColor} />
  </div>
  <div className="block lg:hidden main-nomina3">
    <NominaSmComponent selectedColor={selectedColor} />
  </div></>
  );
}