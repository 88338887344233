import React from 'react';
import Modal from 'react-modal';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useProveedores} from '../../context/proveedoresContext';
import { useNavigate } from "react-router-dom";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import './ModalProveedores.scss';

Modal.setAppElement('#root');

export function ModalComponentProveedor({ isOpen, onRequestClose }) {
    const { createProveedor } = useProveedores();
    const navigate = useNavigate();
  
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentLabel="Ejemplo de Modal"
        className="modal-overlay"
      >
        <div className="ContainerModalProveedores w-full sm:w-2/3 md:w-1/3">
          <div className="flex items-center justify-center">
            <div className="">
              <header className="flex justify-between items-center py-4 font-bold">
                <h3 className="text-xl titulo overline">Nuevo proveedor</h3>
                <button onClick={onRequestClose} className="text-atras">X</button>
              </header>
              <Formik
                initialValues={{
                  nombre: '',
                  sede: ''
                }}
                validationSchema={Yup.object({
                  nombre: Yup.string().required('Nombre es requerido'),
                  sede: Yup.string().required('Sede es requerida'),
                })}
                onSubmit={async (values, actions) => {
                  await createProveedor(values);
                  actions.resetForm();
                  actions.setSubmitting(false);
                  onRequestClose();
                  navigate('/Proveedores');
                }}
              >
                {({ isSubmitting, handleSubmit }) => (
                  <Form onSubmit={handleSubmit}>
                    
                    <label htmlFor="nombre" className="Titulo">Nombre del proveedor</label>
                    <Field className="Input" placeholder="Nombre del proveedor" name="nombre" />
                    <ErrorMessage component="p" name="nombre" className="text-error" />

                    <label htmlFor="sede" className="Titulo">Sede</label>
                    <Field className="Input" placeholder="Sede del proveedor" name="sede" />
                    <ErrorMessage component="p" name="sede" className="text-error" />
                    
                    <button type="submit" className="guardar" disabled={isSubmitting}>
                      {isSubmitting ? (
                        <AiOutlineLoading3Quarters className="animate-spin h-5 w-5" />
                      ) : (
                        'Guardar'
                      )}
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Modal>
    );
}
