import React, { useState, useEffect } from "react";
import './Login.scss';
import { IoEnter } from "react-icons/io5";
import { useUsuarios } from '../../context/usuarioContext';
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../context/AuthContext";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FaUser } from "react-icons/fa";
import { PiLockKey } from "react-icons/pi";
import Modal from 'react-modal';
import { FaCheck } from "react-icons/fa";

// Componente de modal para mostrar un plan de suscripción
const SubscriptionModal = ({ isOpen, onClose, price }) => {
  const handleSubscription = async () => {
    try {
      const response = await fetch('https://server-pos-vkb9.onrender.com/api/checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ priceId: price.id }), // Pasar el userId
      });
      const data = await response.json();
      window.location.href = data.url;
    } catch (error) {
      console.error('Error creating checkout session:', error);
    }
  };

  const isPremiumPlan = price.unit_amount === 5000000; // Ajusta según los precios reales

   // Función para formatear el precio con comas de miles
   const formatPrice = (amount) => {
    return amount.toLocaleString('es-CO', {
      style: 'currency',
      currency: 'COP',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Suscripción"
      className="modal-login-view"
      overlayClassName="overlay-login-view"
    >
      <div className="modal-login-view-2">
        <h2 className="titulo-sub">{price.unit_amount === 5000000 ? "Premium" : "Gratis"}</h2>
        <div className="subscription-info">
        <div className="div-precio mb-5">
          <p className="mr-1 mt-2">
            <span className="simbolo-peso">{formatPrice(price.unit_amount / 100)}</span>
          </p>
          <div className="por-mes-div">
              <span className="por-mes mb-3">por</span>
              <span className="por-mes">mes</span>
            </div>
        </div>
          <p>Descripción del plan</p> {/* Ajusta la descripción según tus necesidades */}
          <ul className="especificaciones-planes">
            <li><FaCheck className="mr-2" />Acceso completo a todas las funciones</li>
            <li><FaCheck className="mr-2" />Soporte prioritario</li>
            <li><FaCheck className="mr-2" />Actualizaciones automáticas</li>
            {isPremiumPlan ? (
              <>
              <li><FaCheck className="mr-2" />Administra hasta 10 sedes</li>
              <li><FaCheck className="mr-2" />Monitorea el estado de tus sedes desde tu celular</li>
              <li><FaCheck className="mr-2" />Registra hasta 20 empleados</li>
              </>
            ) : (
              <li><FaCheck className="mr-2" />Administra hasta 1 sede</li>
            )}
            <li><FaCheck className="mr-2" />Genera facturas ilimitadas</li>
          </ul>
        </div>
        <button className="suscribir2 mb-5" onClick={handleSubscription}>Suscribirme</button>
        <button className="suscribir-cerrar" onClick={onClose}>X</button>
      </div>
    </Modal>
  );
};


export function LoginView() {
  const { getEmpleadoByUsername } = useUsuarios();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth();
  
  const [prices, setPrices] = useState([]);
  const [modalOpenIndex, setModalOpenIndex] = useState(null); // Estado para controlar qué modal está abierto

  useEffect(() => {
    const fetchPrices = async () => {
      try {
        const response = await fetch('https://server-pos-vkb9.onrender.com/api/prices');
        const data = await response.json();
        setPrices(data.prices);
      } catch (error) {
        console.error('Error fetching prices:', error);
      }
    };
    
    fetchPrices();
  }, []);

  const handleLogin = async () => {
    try {
      setLoading(true);
      const empleado = await getEmpleadoByUsername(username);
      if (empleado && empleado.contraseña === password) {
        const { _id, cargo, sede } = empleado; // Obtener el ID del empleado
        login({ username, password, cargo, sede });
        localStorage.setItem('user', JSON.stringify({ username, password, cargo, sede, userId: _id }));
        
        if (cargo === 'cocinera') {
          navigate('/Cocina');
        } else {
          navigate('/services');
        }
      } else {
        setErrorMessage('Credenciales incorrectas');
      }
    } catch (error) {
      console.error('Error al iniciar sesión:', error.message);
      setErrorMessage('Hubo un problema al iniciar sesión. Por favor, inténtalo de nuevo más tarde.');
    } finally {
      setLoading(false);
    }
  };

  const openModal = (index) => {
    setModalOpenIndex(index);
  };

  const closeModal = () => {
    setModalOpenIndex(null);
  };
  
  return (
    <>
      <div className='toda w-full'>
        <div className='left-contentt'>
          <div className="min-h-screen flex flex-col justify-center items-center">
            <div className='content'>
              <div className='inicio flex flex-col justify-center items-center'>
                <div className='seccion-titulo md:flex md:w-full'>
                  <div className='contenedor-login mt-0 sm:mt-0'>
                    <div className="contenedor-credenciales">
                      <h1 className="text-8xl mb-10 sm:text-8xl md:text-9xl lg:text-9xl xl:text-9xl title">Mercurio</h1>
                      <div className="linea-ingresar">
                        <div className="recuadro-input">
                          <FaUser />
                          <input placeholder="USUARIO" value={username} 
                            onChange={e => setUsername(e.target.value.toLowerCase())} 
                            className="input-credenciales">
                          </input>
                        </div>

                        <div className="recuadro-input">
                          <PiLockKey />
                          <input placeholder="CONTRASEÑA" className="input-credenciales" type="password" inputMode="numeric" value={password} onChange={e => setPassword(e.target.value)}></input>
                        </div>

                        <button className='play' onClick={handleLogin} disabled={loading}>
                          {loading ? <AiOutlineLoading3Quarters className="spin" /> : <IoEnter />}
                        </button>

                      </div>
                      
                      {errorMessage && <p className="error-message">{errorMessage}</p>}
                      <div className="ingresar-boton">
                      <div className="div-sub">
                        <h3>¿Aún no tienes una cuenta?</h3>
                        {prices.map((price, index) => (
                          <button key={price.id} className="suscribir" onClick={() => openModal(index)}>
                            {price.unit_amount === 500000 ? "Registrarse Gratis" : "Registrarse Premium"}
                          </button>
                        ))}
                      </div>
                      </div>
                    </div>
                    <div className='flex powered-by'>
                      <h1 className='train'> <span className='trainMini'> powered by </span> EN ORBITA | Mercurio</h1>
                    </div>
                  </div>
                  <div className='contenedor2-login mt-0 sm:mt-0'>
                    <div className="contenedor-credenciales">
                      <div className="circulo1"></div>
                      <div className="circulo2"></div>
                      <div className="circulo3"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {prices.map((price, index) => (
        <SubscriptionModal
          key={price.id}
          isOpen={modalOpenIndex === index}
          onClose={closeModal}
          price={price}
        />
      ))}
    </>
  );
}
