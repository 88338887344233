import React, {useState, useEffect} from 'react';
import './NominaBg.scss';
import { FaUserCircle } from "react-icons/fa";
import { useUsuarios } from '../../context/usuarioContext';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCards} from 'swiper/modules';
import { Pagination } from 'swiper/modules';
import { GrDownload } from "react-icons/gr";
import { useFacturasEmpleados } from '../../context/facturasEmpleadoContext';
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-cards';
import 'swiper/css/navigation';

import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';


export function NominaBgComponent({ selectedColor }) {
  const { usuarios } = useUsuarios();
  const [selectedOption, setSelectedOption] = useState('Ordinario');
  const [horaEntrada, setHoraEntrada] = useState('07:00'); // Hora de entrada por defecto
  const [horaSalida, setHoraSalida] = useState('17:00'); // Hora de salida por defecto
  const [horasTrabajadas, setHorasTrabajadas] = useState(0); // Inicializa las horas trabajadas en 0
  const [totalPagar, setTotalPagar] = useState(0); // Inicializa el total a pagar en 0
  const { createFacturaEmpleado } = useFacturasEmpleados();
  const [empleadoSeleccionado, setEmpleadoSeleccionado] = useState(null);
  const { facturasEmpleados } = useFacturasEmpleados();
  const navigate = useNavigate();
  const [selectedEmpleado, setSelectedEmpleado] = useState('');


  const handleGuardarHora = async () => {
    try {
      if (!empleadoSeleccionado) {
        alert('Por favor, selecciona un empleado antes de guardar la factura.');
        return;
      }

      // Crear objeto con la información de la factura del empleado
      const facturaEmpleado = {
        nombre: empleadoSeleccionado.nombre,
        dia: selectedOption,
        horaEntrada: horaEntrada,
        horaSalida: horaSalida,
        horasTotales: horasTrabajadas,
        totalGanado: totalPagar,
        vendedor: selectedEmpleado,
      };

      // Llamar a la función para crear la factura del empleado
      await createFacturaEmpleado(facturaEmpleado);
      navigate('/Nomina');
      // Notificar al usuario que la factura se ha guardado correctamente
      alert('La factura del empleado ha sido guardada correctamente.');

    } catch (error) {
      console.error('Error al guardar la factura del empleado:', error);
      // Manejar el error de alguna manera, como mostrar un mensaje al usuario
      alert('Ha ocurrido un error al guardar la factura del empleado. Por favor, inténtalo de nuevo.');
    }
  };

  const calcularHorasTrabajadas = (horaEntrada, horaSalida) => {
    const entrada = dayjs(horaEntrada, 'HH:mm');
    const salida = dayjs(horaSalida, 'HH:mm');
    const diferencia = salida.diff(entrada, 'minute'); // Diferencia en minutos
    const horas = Math.floor(diferencia / 60); // Horas completas
    const minutos = diferencia % 60; // Minutos restantes
    return `${horas}.${minutos}`; // Formato de presentación
  };

  // Manejador de cambios en la hora de entrada
  const handleHoraEntradaChange = (hora) => {
    setHoraEntrada(hora);
    const horas = calcularHorasTrabajadas(hora, horaSalida);
    setHorasTrabajadas(horas);
    const total = calcularTotalPagar(horas, selectedOption, empleadoSeleccionado); // Recalcular el total a pagar
    setTotalPagar(total);
  };

  // Manejador de cambios en la hora de salida
  const handleHoraSalidaChange = (hora) => {
    setHoraSalida(hora);
    const horas = calcularHorasTrabajadas(horaEntrada, hora);
    setHorasTrabajadas(horas);
    const total = calcularTotalPagar(horas, selectedOption, empleadoSeleccionado); // Recalcular el total a pagar
    setTotalPagar(total);
  };

  useEffect(() => {
    if (usuarios.length > 0 && usuarios[0].empleados.length > 0 && empleadoSeleccionado) {
      const total = calcularTotalPagar(horasTrabajadas, selectedOption, empleadoSeleccionado);
      setTotalPagar(total);
    }
  }, [horasTrabajadas, selectedOption, empleadoSeleccionado, usuarios]);

  // Función para calcular el total a pagar según el día seleccionado
  const calcularTotalPagar = (horasTrabajadas, selectedOption, empleado) => {
    if (!empleado) return 0; // Salir si no hay empleado seleccionado
    let valorHora = 0;
    switch (selectedOption) {
      case 'Ordinario':
        valorHora = empleado.horaOrdinaria;
        break;
      case 'Dominical':
        valorHora = empleado.horaDominical;
        break;
      case 'Festivo':
        valorHora = empleado.horaFestivo;
        break;
      default:
        valorHora = 0; // Por defecto no se paga nada si no se selecciona un día válido
        break;
    }
    const totalPagar = horasTrabajadas * valorHora;
    return totalPagar.toFixed(2); // Redondea el total a dos decimales
  };

  // Verificar si hay al menos una empresa con empleados
  if (!usuarios.length || !usuarios[0].empleados.length) {
    return <div>No hay información de empleados disponibles</div>;
  }

  const empleados = usuarios[0].empleados;

  return (
    <div className="main-nomina">
      <div className="lg:w-2/3 flex hidden md:block">
        <Swiper
          className="custom-swiper"
          effect={'cards'}
          grabCursor={true}
          pagination={true}
          modules={[EffectCards, Pagination]}
        >
          {empleados.map((empleado) => (
            <SwiperSlide
              className='swiper-slide asd'
              style={{ borderColor: selectedColor }}
              key={empleado._id}
              onClick={() => setEmpleadoSeleccionado(empleado)}
            >
              <div className='card-empleado' style={{ borderColor: selectedColor }}>
                <div className='info-main-empleado'>
                  <div className='info-contacto'>
                    <FaUserCircle className='icon-foto mt-10 md:mt-0' style={{ color: selectedColor }} />
                    <h1 className='nombre-empleado'>{empleado.nombre}</h1>
                  </div>
                  <div className='info-contacto'>
                    <div className='' style={{ borderColor: selectedColor }}>
                      <p>Cédula: {empleado.cedula}</p>
                      <p>Dirección: {empleado.direccion}</p>
                      <p>Celular: {empleado.celular}</p>
                      <p>Cargo: {empleado.cargo}</p>
                      <div className='horas' style={{ borderColor: selectedColor }}>
                        <p>Hora Ordinaria: {empleado.horaOrdinaria}</p>
                        <p>Hora Dominical: {empleado.horaDominical}</p>
                        <p>Hora Festivo: {empleado.horaFestivo}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='info-main-empleado2' style={{ borderColor: selectedColor }}>
                  <div className='info-contacto'>
                    <button
                      className={`opcion ${selectedOption === 'Ordinario' ? 'selected-nom' : 'opciones-nom'}`}
                      onClick={() => setSelectedOption('Ordinario')}
                      style={{ borderColor: selectedColor }}
                    >
                      Ordinario
                    </button>
                    <button
                      className={`opcion ${selectedOption === 'Dominical' ? 'selected-nom' : 'opciones-nom'}`}
                      onClick={() => setSelectedOption('Dominical')}
                      style={{ borderColor: selectedColor }}
                    >
                      Dominical
                    </button>
                    <button
                      className={`opcion ${selectedOption === 'Festivo' ? 'selected-nom' : 'opciones-nom'}`}
                      onClick={() => setSelectedOption('Festivo')}
                      style={{ borderColor: selectedColor }}
                    >
                      Festivo
                    </button>
                    <button
                      className={`opcion ${selectedOption === 'Falta' ? 'selected-nom' : 'opciones-nom'}`}
                      onClick={() => setSelectedOption('Falta')}
                      style={{ borderColor: selectedColor }}
                    >
                      NT
                    </button>
                  </div>
                  <div className='info-horas'>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={[
                          'DesktopTimePicker',
                        ]}
                      >
                        <DemoItem label="Hora Entrada">
                          <MobileTimePicker value={horaEntrada} onChange={handleHoraEntradaChange} />
                        </DemoItem>
                        <DemoItem label="Hora Salida">
                          <MobileTimePicker value={horaSalida} onChange={handleHoraSalidaChange} />
                        </DemoItem>
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                </div>
                <div className='footer-card-emp flex'>
                  <p className='total-pagar-emp' style={{ backgroundColor: selectedColor }}>Horas trabajadas: {horasTrabajadas} </p>
                  <p className='total-pagar-emp' style={{ backgroundColor: selectedColor }}>Total a pagar: {totalPagar}</p>
                  <div>
                    <GrDownload className='guardar-hora' onClick={handleGuardarHora} />
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className='w-1/3 hidden md:block'>
        <div className='capsula-pq-lista'>
        <div className='header-analitica'>
          <select className='select-usuario' value={selectedEmpleado} onChange={(e) => setSelectedEmpleado(e.target.value)}>
            <option value="">Selecciona un empleado</option>
            {/* Renderizar las opciones dentro del select */}
            {usuarios.length > 0 && usuarios[0].empleados.map(empleado => (
              <option key={empleado._id} value={empleado.usuario}>{empleado.usuario}</option>
            ))}
          </select>
        </div>
          <div className='abajo-emp'>
            {/* Iterar sobre las facturas de empleados y mostrar la información */}
            {facturasEmpleados.map((factura, index) => (
              <div className='card-lista-emp' style={{ borderColor: selectedColor }} key={index}>
                <div className='emp-lista'>
                  <FaUserCircle className='icon-foto-lista' style={{ color: selectedColor }} />
                  <p>{factura.nombre}</p>
                </div>
                <div className='info-final-emp'>
                  <p>{moment(factura.horaEntrada).format('HH:mm')}</p>
                  <p>{moment(factura.horaEntrada).format('HH:mm')}</p>
                </div>
                <div className='info-final-emp'>
                  <p>{factura.horasTotales.toFixed(2)}</p>
                  <p className='nom-peq'>Horas laboradas</p>
                </div>
                <div className='info-final-emp'>
                  <p>{factura.totalGanado}</p>
                  <p className='nom-peq'>Ganado</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
