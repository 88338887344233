import React, { useState, useEffect } from 'react';

export function VoiceCommand  () {
  const [transcript, setTranscript] = useState('');

  useEffect(() => {
    const recognition = new window.webkitSpeechRecognition();
    recognition.lang = 'es-ES';
    recognition.continuous = true;
    recognition.interimResults = true;

    recognition.onresult = (event) => {
      for (let i = event.resultIndex; i < event.results.length; ++i) {
        if (event.results[i].isFinal) {
          const result = event.results[i][0].transcript.trim();
          setTranscript(result);
        }
      }
    };

    recognition.start();

    return () => recognition.stop();
  }, []);

  return (
    <div>
      <p>Di algo...</p>
      <p>Comando reconocido: {transcript}</p>
    </div>
  );
};