import { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";

const FacturaContext = createContext();

export const useFacturas = () => {
  const context = useContext(FacturaContext);
  if (!context) throw new Error("Factura Provider is missing");
  return context;
};

export const FacturaProvider = ({ children }) => {
  const [facturas, setFacturas] = useState([]);
  const baseURL = "https://server-pos-vkb9.onrender.com/api/facturas";

  const fetchFacturas = async () => {
    try {
      const res = await axios.get(baseURL);
      setFacturas(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchFacturas();
  }, []);

  const deleteFactura = async (id) => {
    try {
      const res = await axios.delete(`${baseURL}/${id}`);
      if (res.status === 204) {
        setFacturas(facturas.filter((factura) => factura._id !== id));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const createFactura = async (factura) => {
    try {
      const res = await axios.post(baseURL, factura);
      setFacturas([...facturas, res.data]);
    } catch (error) {
      console.error(error);
    }
  };

  const getFactura = async (id) => {
    try {
      const res = await axios.get(`${baseURL}/${id}`);
      return res.data;
    } catch (error) {
      console.error(error);
    }
  };

  const updateFactura = async (id, factura) => {
    try {
      const res = await axios.put(`${baseURL}/${id}`, factura);
      setFacturas(facturas.map((factura) => (factura._id === id ? res.data : factura)));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <FacturaContext.Provider
      value={{
        facturas,
        deleteFactura,
        createFactura,
        getFactura,
        updateFactura,
      }}
    >
      {children}
    </FacturaContext.Provider>
  );
};