import React, { useState } from 'react';
import './Producto.scss';
import { IoIosWarning } from 'react-icons/io';
import { FcAddImage } from 'react-icons/fc';

export function PostCard({ post, onEdit, selectedColor, isSelected, onInsumoSelect }) {
  const [selectedInsumo, setSelectedInsumo] = useState(null);

  const showCuidadoIcon = post.cantidad <= 3;

  const formatPrecio = (precio) => {
    return precio.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const showInsumos = post.categoria === 'Almuerzo' && post.insumos && post.insumos.length > 0;

  const handleInsumoClick = (insumo) => {
    if (selectedInsumo && selectedInsumo.nombreInsumo === insumo.nombreInsumo) {
      setSelectedInsumo(null);
      onInsumoSelect(null);
    } else {
      setSelectedInsumo(insumo);
      onInsumoSelect(insumo);
    }
  };

  return (
    <div className={`ContainerProducto ${isSelected ? 'seleccionado' : ''}`} onClick={() => onEdit(post)} style={{ borderColor: selectedColor }}>
      <div className='flex todo-card'>
        <div className='info-card'>
          <div className='image-container-services'>
            {post.image ? (
              <img className="image-services" src={post.image.url} alt={post.title} />
            ) : (
              <FcAddImage className="default-image" alt="default" />
            )}
          </div>
          {showCuidadoIcon && <IoIosWarning className="warning-icon" />}
          <p className="warning-icon2"> {post.cantidad} </p>
          <div className="InfoContainer">
            <p className="titulo1">{post.title} {selectedInsumo ? ` - ${selectedInsumo.nombreInsumo}` : ''}</p>
            <h3 className="Precio">${formatPrecio(post.precio)}</h3>
          </div>
        </div>
        {showInsumos && (
          <div className="InsumosContainer pl-5">
            {post.insumos.map((insumo, index) => (
              <div
                key={index}
                className={`NombreInsumo ${selectedInsumo === insumo ? 'selected' : ''}`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleInsumoClick(insumo);
                }}
              >
                {insumo.nombreInsumo}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
