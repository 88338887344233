import { usePosts } from "../../context/postContext";
import { useAuth } from "../../context/AuthContext";
import { PostCard } from "../../components/PostCard/PostCard";
import './Service.scss';
import React, { useState, useEffect  } from 'react';
import { AiOutlineMinusCircle, AiOutlineAppstoreAdd, AiFillCloseCircle, AiOutlinePlusCircle } from 'react-icons/ai';
import { CiGrid41 } from 'react-icons/ci'
import { GiHamburger } from 'react-icons/gi';
import { FaBorderAll  } from "react-icons/fa6";
import { FaPerson } from "react-icons/fa6";
import { LuCandy } from "react-icons/lu";
import { useFacturas } from "../../context/facturaContext";
import { useCocina } from "../../context/cocinaContext";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useClientes } from "../../context/clienteContext";
import { FaCashRegister } from "react-icons/fa";
import { MdFreeBreakfast } from "react-icons/md";
import { ModalCajaTrabajadores } from '../../components/ModalCajaTrabajadores/ModalCajaTrabajadores';
import { TiDeleteOutline } from "react-icons/ti";
import { IoIosRefresh } from "react-icons/io";
import { CiDeliveryTruck } from "react-icons/ci";
import { FaMicrophone } from "react-icons/fa6";
import { FcAddImage } from 'react-icons/fc';

const initialState = {
  selectedProductsByTable: {},
  showQuantityWarning: false,
  isProductModalOpen: false,
  searchTerm: '',
  selectedClient: '',
  isSelling: false,
  valorRecibido: '',
  metodoPago: '',
  observacion: '',
  selectedTable: 1, // Mesa 1 seleccionada por defecto
  currentCategory: 'Todos',
  selectedProducts: []
};

export function HomePage({ selectedColor }) {
  const { posts, updatePost, getPosts } = usePosts();
  const { createFactura } = useFacturas();
  const { createCocina } = useCocina();
  const { clientes, updateCliente } = useClientes();

  //const [selectedProductsByTable, setSelectedProductsByTable] = useState(initialState.selectedProductsByTable);
  const [showQuantityWarning, setShowQuantityWarning] = useState(initialState.showQuantityWarning);
  const [isProductModalOpen, setIsProductModalOpen] = useState(initialState.isProductModalOpen);
  const [searchTerm, setSearchTerm] = useState(initialState.searchTerm);
  const [selectedClient, setSelectedClient] = useState(initialState.selectedClient);
  const [isSelling, setIsSelling] = useState(initialState.isSelling);
  const [valorRecibido, setValorRecibido] = useState(initialState.valorRecibido);
  //const [metodoPago, setMetodoPago] = useState(initialState.metodoPago);
  const [metodosPago, setMetodosPago] = useState([{ metodo: 'efectivo', cantidad: '' }]);


  const [observacion, setObservacion] = useState(initialState.observacion);
  //const [selectedTable, setSelectedTable] = useState(initialState.selectedTable);
  const [currentCategory, setCurrentCategory] = useState(initialState.currentCategory);
  const [selectedProducts, setSelectedProducts] = useState(initialState.selectedProducts);
  const [errorMessage, setErrorMessage] = useState("");
  const { user } = useAuth();
  const [/*valorAbono,*/ setValorAbono] = useState("");
  //const [isAbonoSelected, setIsAbonoSelected] = useState(false);
  const [showCocinaButton, setShowCocinaButton] = useState(true);
  const [isModalOpenProducto, setIsModalOpenProducto] = useState(false);
  const [selectedInsumo, setSelectedInsumo] = useState(null); // Nuevo estado para el insumo seleccionado
  const variableFiltrarSede = posts.filter(post => post.sede === user.sede);
  const [showMessage, setShowMessage] = useState(false);
  const [messageContent, setMessageContent] = useState("");
  const [isDomicilio, setIsDomicilio] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [voiceMessage, setVoiceMessage] = useState('');

  const toggleListening = () => {
    setIsActive(!isActive);
    setIsListening(!isListening);
  };

  const handleMetodoPagoChange = (index, metodo) => {
    const nuevosMetodosPago = [...metodosPago];
    nuevosMetodosPago[index] = { ...nuevosMetodosPago[index], metodo };
    setMetodosPago(nuevosMetodosPago);
  };
  
  const handleCantidadChange = (index, cantidad) => {
    const nuevosMetodosPago = [...metodosPago];
    nuevosMetodosPago[index] = { ...nuevosMetodosPago[index], cantidad };
    setMetodosPago(nuevosMetodosPago);
  };
  
  
  const addMetodoPago = () => {
    setMetodosPago([...metodosPago, { metodo: '', cantidad: '' }]);
  };
  
  const removeMetodoPago = (index) => {
    const nuevosMetodosPago = metodosPago.filter((_, i) => i !== index);
    setMetodosPago(nuevosMetodosPago);
  };
   
  

  const [selectedProductsByTable, setSelectedProductsByTable] = useState(() => {
    const saved = localStorage.getItem(`${user.username}-selectedProductsByTable`);
    return saved ? JSON.parse(saved) : {};
  });
  
  const [selectedTable, setSelectedTable] = useState(() => {
    const savedTable = localStorage.getItem(`${user.username}-selectedTable`);
    return savedTable ? JSON.parse(savedTable) : 1; // Defaults to table 1 if nothing is stored
  });

  const [cocinaActionActive, setCocinaActionActive] = useState(() => {
    const saved = localStorage.getItem(`${user.username}-cocinaActionActive`);
    return saved ? JSON.parse(saved) : false;
  });
  
  
  useEffect(() => {
    localStorage.setItem(`${user.username}-selectedProductsByTable`, JSON.stringify(selectedProductsByTable));
    localStorage.setItem(`${user.username}-selectedTable`, JSON.stringify(selectedTable));
    localStorage.setItem(`${user.username}-cocinaActionActive`, JSON.stringify(cocinaActionActive));
  }, [selectedProductsByTable, selectedTable, cocinaActionActive, user.username]);
  

  useEffect(() => {
    const savedProducts = localStorage.getItem(`${user.username}-selectedProductsByTable`);
    const savedTable = localStorage.getItem(`${user.username}-selectedTable`);
  
    if (savedTable) {
      setSelectedTable(JSON.parse(savedTable));
    }
  
    if (savedProducts) {
      const formattedProducts = JSON.parse(savedProducts);
      setSelectedProductsByTable(formattedProducts);
      const productsForTable = formattedProducts[selectedTable];
      if (productsForTable) {
        setSelectedProducts(productsForTable);
      }
    }
  }, [user.username, selectedTable]);
// Luego, actualizar los productos seleccionados cuando cambia selectedTable o selectedProductsByTable
useEffect(() => {
  const productsForTable = selectedProductsByTable[selectedTable];
  if (productsForTable) {
    setSelectedProducts(productsForTable);
  }
}, [selectedTable, selectedProductsByTable]);


  useEffect(() => {
    const handleLoad = () => {
      const tableFromStorage = localStorage.getItem('selectedTable');
      if (tableFromStorage) {
        setSelectedTable(JSON.parse(tableFromStorage));
      }
    };
  
    handleLoad();
  }, []); // El array vacío asegura que este efecto solo se ejecuta una vez al montar el componente.
  

  ////////RECONOCIMIENTO DE VOZ////////////////////
  const normalizeText = (text) => {
    const commonWords = new Set(['de', 'la', 'el', 'los', 'las', 'un', 'una', 'unos', 'unas', 'con', 'a', 'y']); // Agregados 'y'
    text = text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^a-z0-9\s]/gi, "").toLowerCase().trim();
    
    text = text.split(' ')
      .map(word => word.endsWith('es') ? word.slice(0, -2) : (word.endsWith('s') ? word.slice(0, -1) : word))
      .filter(word => !commonWords.has(word)) // Eliminar palabras comunes
      .join(' ');
  
    return text;
  };
  

  const soundex = (s) => {
    const codes = { a: '', e: '', i: '', o: '', u: '', h: '', w: '', y: '', b: '1', f: '1', p: '1', v: '1', c: '2', g: '2', j: '2', k: '2', q: '2', s: '2', x: '2', z: '2', d: '3', t: '3', l: '4', m: '5', n: '5', r: '6' };
    const result = [...s.toLowerCase().replace(/[^a-z]/g, '')].map(letter => codes[letter] || '');
    return result.filter((value, index, self) => index === 0 || value !== self[index - 1]).join('');
  };
  
  // Función de distancia de Levenshtein
  const levenshteinDistance = (a, b) => {
    const matrix = [];
    for (let i = 0; i <= b.length; i++) {
      matrix[i] = [i];
    }
    for (let j = 0; j <= a.length; j++) {
      matrix[0][j] = j;
    }
    for (let i = 1; i <= b.length; i++) {
      for (let j = 1; j <= a.length; j++) {
        const substitutionCost = (b[i - 1] === a[j - 1]) ? 0 : 1;
        matrix[i][j] = Math.min(
          matrix[i - 1][j] + 1,                // deletion
          matrix[i][j - 1] + 1,                // insertion
          matrix[i - 1][j - 1] + substitutionCost  // substitution
        );
      }
    }
    return matrix[b.length][a.length];
  };
  
  // Revisión de coincidencia ajustada con tolerancia a variaciones
  const isSimilarEnough = (input, target) => {
    const inputNormalized = normalizeText(input);
    const targetNormalized = normalizeText(target);
  
    const inputSoundex = soundex(inputNormalized);
    const targetSoundex = soundex(targetNormalized);
  
    // Primero comparamos con Soundex
    if (inputSoundex === targetSoundex) {
      return true;
    }
  
    // Comprobación palabra por palabra con una mayor tolerancia
    const inputWords = inputNormalized.split(" ");
    const targetWords = targetNormalized.split(" ");
    return targetWords.every(targetWord => 
      inputWords.some(inputWord => 
        levenshteinDistance(inputWord, targetWord) <= Math.max(1, Math.floor(targetWord.length * 0.3))
      )
    );
  };
  
  
  const VoiceCommand = ({ handleProductSelection, posts, isListening, setVoiceMessage }) => {
    useEffect(() => {
        const recognition = new window.webkitSpeechRecognition();
        recognition.lang = 'es-ES';
        recognition.continuous = true;
        recognition.interimResults = false;

        recognition.onresult = (event) => {
            const lastResult = event.results[event.resultIndex];
            if (lastResult.isFinal) {
                const transcript = lastResult[0].transcript.trim();
                const productsFilteredByLocation = posts.filter(post => post.sede === user.sede);
                const productToSelect = productsFilteredByLocation.find(p => isSimilarEnough(p.title, transcript));
                if (productToSelect) {
                    handleProductSelection(productToSelect);
                    setVoiceMessage(`Seleccionado: ${productToSelect.title}`);
                    setTimeout(() => setVoiceMessage(''), 2200); // Limpia el mensaje después de 3 segundos
                } else {
                    setVoiceMessage("¿Puedes repetir la petición?");
                    setTimeout(() => setVoiceMessage(''), 2200);
                }
            }
        };

        if (isListening) {
            recognition.start();
            //console.log("Reconocimiento de voz activado");
        } else {
            recognition.stop();
            //console.log("Reconocimiento de voz detenido");
        }

        return () => recognition.abort();
    }, [isListening, handleProductSelection, posts, setVoiceMessage]);

    return null;
};

  const handleClickRefresh = async () => {
    try {
      await getPosts(); // Call the function and ensure it updates the state correctly
    } catch (error) {
      console.error("Error refreshing posts:", error);
    }
  };

  const hasKitchenValidProducts = () => {
    return selectedProducts.some(product => product.categoria === 'Almuerzo' || product.categoria === 'Desayuno');
  };


  const handleTableChange = (tableNumber) => {
    setSelectedTable(tableNumber);
    if (!selectedProductsByTable[tableNumber]) {
      setSelectedProducts([]);
    } else {
      setSelectedProducts(selectedProductsByTable[tableNumber]);
    }
  };

  const handleInsumoSelect = (insumo, product) => {
    setSelectedInsumo(insumo); // Almacena el insumo seleccionado

    const currentTableProducts = selectedProductsByTable[selectedTable] || [];
    const lastProduct = currentTableProducts[currentTableProducts.length - 1];

    if (!lastProduct) {
        setErrorMessage("Ahora seleccione el producto.");
        return;
    }

    if (product) {
      handleProductSelection(product, insumo);
  }

    // Clona el último producto seleccionado y añade el insumo seleccionado
    const updatedProduct = { ...lastProduct, insumo: insumo, quantity: 1 };

    // Añade el producto clonado con el insumo a la lista de productos seleccionados
    const updatedProducts = [...currentTableProducts, updatedProduct];
    setSelectedProductsByTable({ ...selectedProductsByTable, [selectedTable]: updatedProducts });
    setSelectedProducts(updatedProducts);
};

  
const handleProductSelection = (product, insumoSelected = null) => {
  const selectedProductsForTable = selectedProductsByTable[selectedTable] || [];
  const existingProductIndex = selectedProductsForTable.findIndex((p) => p._id === product._id);

  if (product.cantidad <= 0) {
      setErrorMessage("No puedes seleccionar este producto porque su cantidad es 0.");
      return;
  }

  // Verifica si el producto requiere insumos y si no ha sido seleccionado un insumo todavía
  if (product.requiereInsumo && !insumoSelected && !selectedInsumo) {
      setErrorMessage("Por favor, seleccione un insumo para este producto primero.");
      return;
  }

  const insumo = insumoSelected || selectedInsumo;

  if (existingProductIndex !== -1) {
      // Si el producto ya está seleccionado, remuévelo de la lista
      const updatedProducts = [
          ...selectedProductsForTable.slice(0, existingProductIndex),
          ...selectedProductsForTable.slice(existingProductIndex + 1)
      ];
      setSelectedProductsByTable({ ...selectedProductsByTable, [selectedTable]: updatedProducts });
      setSelectedProducts(updatedProducts);
  } else {
      // Si el producto no está seleccionado, agrégalo a la lista
      const newSelectedProduct = { ...product, quantity: 1, insumo: insumo };
      const newSelectedProducts = [...selectedProductsForTable, newSelectedProduct];
      setSelectedProductsByTable({ ...selectedProductsByTable, [selectedTable]: newSelectedProducts });
      setSelectedProducts(newSelectedProducts);
  }
};


  useEffect(() => {
    const timer = setTimeout(() => {
      setErrorMessage("");
    }, 1600); // Ocultar el mensaje después de 3 segundos

    return () => clearTimeout(timer);
  }, [errorMessage]);
  
  
  //const handleSeleccionarMetodoPago = (metodo) => {
    //setMetodoPago(metodo);
  //};

  const calculateCambio = () => {
    const totalFactura = calculateInvoiceTotal();
    return valorRecibido - totalFactura;
  };

  const calculateInvoiceTotal = () => {
    let total = 0;
    selectedProducts.forEach((product) => {
      total += calculateProductTotal(product);
    });
  
    const metodoAbono = metodosPago.find(metodo => metodo.metodo === "abono");
    if (metodoAbono) {
      total = parseFloat(metodoAbono.cantidad || 0);
    }
  
    return total;
  };
  

  //const handleCategoryChange = (category) => {
    //setCurrentCategory(category);
    //setSelectedTable('');
    //setSelectedProducts([]);
  //};

  const handleCategoryChange = (category) => {
    setCurrentCategory(category);
  };

  const updateProductQuantity = async (productId, newQuantity) => {
    try {
      const productToUpdate = {
        cantidad: newQuantity
      };
    
      const response = await updatePost(productId, productToUpdate);
      console.log(`Producto ${productId} actualizado:`, response);
    
    } catch (error) {
      console.error("Error al actualizar la cantidad del producto:", error);
    }
  };
  
  
  

  const clearSelectedProducts = () => {
    setSelectedProducts([]);
    setSelectedProductsByTable({ ...selectedProductsByTable, [selectedTable]: [] });
  };
  
  const handleSell = async () => {
    if (isSelling) return;
    setIsSelling(true);
  
    try {
      const totalPagado = metodosPago.reduce((acc, pago) => acc + parseFloat(pago.cantidad || 0), 0);
      const totalFactura = calculateInvoiceTotal();
  
      if (totalPagado < totalFactura) {
        alert("El total pagado es menor que el total de la factura.");
        setIsSelling(false);
        return;
      }
  
      const cambio = totalPagado - totalFactura;
      const factura = {
        cliente: selectedClient,
        vendedor: user.username,
        sede: user.sede,
        productos: selectedProducts.map((product) => ({
          nombre: product.title,
          categoria: product.categoria,
          cantidad: product.quantity,
          precio: product.precio,
          total: product.precio * product.quantity,
          nombreProteina: selectedInsumo ? selectedInsumo.nombreInsumo : null,
        })),
        cantidadTotal: selectedProducts.reduce((total, product) => total + product.quantity, 0),
        metodosPago: metodosPago.filter(pago => pago.metodo && pago.cantidad),  // Filtra métodos de pago vacíos
        totalFactura: totalFactura,
        dineroDevuelto: cambio,
        observacion: observacion,
      };
  
      await createFactura(factura);
      setMessageContent("Factura generada!");
      setShowMessage(true);
      setTimeout(() => {
        setShowMessage(false);
      }, 500);
  
      const esFiado = metodosPago.some(p => p.metodo === "fiado");
      const esAbono = metodosPago.some(p => p.metodo === "abono");
  
      if (!esAbono) {
        for (const product of selectedProducts) {
          const updatedQuantity = product.cantidad - product.quantity;
          if (updatedQuantity < 0) {
            setErrorMessage(`No hay suficiente cantidad para el producto ${product.title}`);
            setIsSelling(false);
            return;
          }
          await updateProductQuantity(product._id, updatedQuantity);
        }
      }
  
      if ((esFiado || esAbono) && selectedClient) {
        const cliente = clientes.find(cliente => cliente.nombre === selectedClient);
        if (!cliente) {
          alert("Por favor, seleccione un cliente.");
          setIsSelling(false);
          return;
        }
        if (cliente) {
          let nuevaDeuda;
          if (esFiado) {
            nuevaDeuda = (cliente.deuda || 0) + totalFactura;
          } else if (esAbono) {
            nuevaDeuda = Math.max((cliente.deuda || 0) - totalFactura, 0);
          }
          await updateCliente(cliente._id, { deuda: nuevaDeuda });
        }
      }
  
      clearSelectedProducts();
      setValorRecibido('');
      setValorAbono('');
      setIsDomicilio('');
      setSelectedClient('');
      setObservacion('');
      setMetodosPago([{ metodo: 'efectivo', cantidad: '' }]);
      setIsSelling(false);
    } catch (error) {
      console.error("Error al guardar la factura:", error);
      setIsSelling(false);
    }
  };
  


  const cocinaSell = async () => {
    try {
      setShowCocinaButton(false);
      const factura = {
        // other properties
        cliente: selectedClient,
        vendedor: user.username,
        sede: user.sede,
        estado: "pendiente",
        mesa: selectedTable.toString(),
        domicilio: isDomicilio ? "domicilio" : "",
        productos: metodosPago === "abono" ? [] : selectedProducts.map((product) => ({
          nombre: product.title,
          categoria: product.categoria,
          cantidad: product.quantity,
          precio: product.precio,
          total: product.precio * product.quantity,
          nombreProteina: selectedInsumo ? selectedInsumo.nombreInsumo : null,
        })),
        observacion: observacion,
      };
  
      await createCocina(factura);
      setMessageContent("Producto enviado a cocina con éxito!");
      setShowMessage(true);
      setTimeout(() => {
        setShowMessage(false);
      }, 2000);
      setCocinaActionActive(true); // This should trigger a useEffect to update localStorage
    } catch (error) {
      setIsSelling(false);
      setMessageContent("Error al enviar a cocina");
      setShowMessage(true);
      setTimeout(() => {
        setShowMessage(false);
      }, 2000);
    }
  };  

  const handleIncrement = (product) => {
    const selectedProductsForTable = selectedProductsByTable[selectedTable] || [];
    const availableQuantity = product.cantidad || 0;
    const currentQuantity = getProductQuantity(product);
  
    if (currentQuantity < availableQuantity) {
      const updatedProducts = selectedProductsForTable.map((p) => {
        if (p._id === product._id) {
          return { ...p, quantity: (p.quantity || 0) + 1 };
        }
        return p;
      });
      setSelectedProductsByTable({ ...selectedProductsByTable, [selectedTable]: updatedProducts });
      setSelectedProducts(updatedProducts);
    } else {
      setShowQuantityWarning(true);
    }
  };
  
  const handleDecrement = (product) => {
    const selectedProductsForTable = selectedProductsByTable[selectedTable] || [];
    const currentQuantity = getProductQuantity(product);
  
    if (currentQuantity > 1) {
      const updatedProducts = selectedProductsForTable.map((p) => {
        if (p._id === product._id) {
          return { ...p, quantity: (p.quantity || 0) - 1 };
        }
        return p;
      });
      setSelectedProductsByTable({ ...selectedProductsByTable, [selectedTable]: updatedProducts });
      setSelectedProducts(updatedProducts);
    } else {
      const updatedProducts = selectedProductsForTable.filter((p) => p._id !== product._id);
      setSelectedProductsByTable({ ...selectedProductsByTable, [selectedTable]: updatedProducts });
      setSelectedProducts(updatedProducts);
    }
  };

  const getProductQuantity = (product) => {
    return product.quantity || 1;
  };

  const calculateProductTotal = (product) => {
    return (product.precio || 0) * (product.quantity || 0);
  };

  const closeModalProduct = () => {
    setIsProductModalOpen(false);
  };

  const renderCategoryIcon = (category) => {
    switch (category) {
      case 'Todos':
        return <FaBorderAll />;
      case 'Desayuno':
        return <MdFreeBreakfast />;
      case 'Almuerzo':
        return <GiHamburger />;
      case 'Golosinas':
        return <LuCandy />;
      case 'Cliente-Especial':
        return <FaPerson />;
      default:
        return null;
    }
  };


  const renderPost = () => {
    const filteredPosts = currentCategory === 'Todos'
      ? variableFiltrarSede.filter((post) => post.title.toLowerCase().includes(searchTerm.toLowerCase()))
      : variableFiltrarSede
        .filter((post) => post.categoria === currentCategory)
        .filter((post) => post.title.toLowerCase().includes(searchTerm.toLowerCase()));
  
    return (
      <div className="grid-productos grid grid-cols-2 sm:grid-cols-4 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-7 gap-4">
        {filteredPosts.map((post) => (
          <div
            key={post._id}
            onInsumoSelect={handleInsumoSelect}
            className={`producto ${selectedProducts.some((selectedProduct) => selectedProduct._id === post._id) ? 'seleccionado' : ''}`}
            onClick={() => handleProductSelection(post)}
          >
            <PostCard
              post={post}
              onEdit={() => handleProductSelection(post)}
              selectedColor={selectedColor}
              onInsumoSelect={handleInsumoSelect}
              isSelected={selectedProducts.some((selectedProduct) => selectedProduct._id === post._id)}
            />
          </div>
        ))}
      </div>
    );
  };
  
  

  const handleSearchChange = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
  };

  const openModalProducto = () => {
    setIsModalOpenProducto(true);
  };

  const closeModalProducto = () => {
    setIsModalOpenProducto(false);
  };

  const clearSearch = () => {
    setSearchTerm('');
  };

  const hasValidProductsForKitchen = () => {
    return selectedProducts.some(product => product.categoria === 'Almuerzo' || product.categoria === 'Desayuno');
  };
  

  return (
    <div className="main-services">
      <div className={`overlay-main ${isActive ? 'active' : ''}`}></div>
      <div className="voice-command-container">
      {isActive && (
          <h1 className="message2">{voiceMessage}</h1>
        )}
        <FaMicrophone 
          className={`floating-icon ${isActive ? 'active' : ''}`} 
          onClick={toggleListening} 
        />
        {isActive && posts && posts.length > 0 && (
          <VoiceCommand
            handleProductSelection={handleProductSelection}
            posts={posts}
            isListening={isActive}
            setVoiceMessage={setVoiceMessage}
          />
        )}
      </div>


      {showMessage && (
        <div className="message-overlay">
          <div className="message-content">
            {messageContent}
          </div>
        </div>
      )}
      <div className={`main-content-services ${selectedColor}`}></div>
      <div className="w-full flex flex-col md:flex-row">
        <div className="w-full lg:w-3/4 side left hidden lg:block">
          <header className={`main-content flex justify-between items-center my-4 background-main barra-opciones`}>
            <h1 className="font-bold titulo text-2xl ml-5 flex">
              <div className="buscador flex ">
                <input
                  type="text"
                  placeholder="Buscar por título..."
                  value={searchTerm}
                  onChange={handleSearchChange}
                  //style={{ borderColor: selectedColor }}
                />
                {searchTerm && (
                  <TiDeleteOutline onClick={clearSearch} />
                )}
              </div>
            </h1>
            <div className="categorias-tabs">
              {[ 'Todos', 'Desayuno',  'Almuerzo', 'Cliente-Especial' ].map((category) => (
                <button
                  key={category}
                  onClick={() => handleCategoryChange(category)}
                  className={`categoria-tab ${currentCategory === category ? 'active' : ''}`}
                  
                >
                  {renderCategoryIcon(category)}
                </button>
              ))}
            </div>
            <p><span className="nombre-usuario">{user.username.toUpperCase()}</span></p>

            <div className="flex">
              <FaCashRegister className="refresh" onClick={openModalProducto} />
              <IoIosRefresh
                  className="refresh ml-2"
                  onClick={handleClickRefresh}
              />
            </div>

            <ModalCajaTrabajadores
                isOpen={isModalOpenProducto}
                onRequestClose={closeModalProducto}
              />

          </header>
  
          <div className="Productos">
            {renderPost()}
          </div>
  
          {/* Buttons for table selection */}
          <div className="comidas-tab">
            <div className="div-botones-mesa">
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map((tableNumber) => (
                <button
                    key={tableNumber}
                    className={`botones-mesa ${selectedTable === tableNumber ? 'active' : ''}`}
                    onClick={() => handleTableChange(tableNumber)}
                    style={{ backgroundColor: selectedTable === tableNumber ? 'black' : 'initial', color: selectedTable === tableNumber ? 'white' : 'initial' }}
                >
                    {tableNumber}
                </button>
            ))}
            </div>


          </div>
        </div>
  
        <div className=""></div>
        <div className="w-full mr-9 lg:w-1/4 Factura">
          <div>
            <div className="flex items-center container-titulo">
            </div>
  
            {isProductModalOpen && (
              <div className="Modal">
                <div className="closebutton" onClick={closeModalProduct}>
                  <AiFillCloseCircle />
                </div>
                <div className="modal-contentt">
                  <div className="modal-header">
                    <div className="modal-options">
                      <div className="buscador-sm flex ">
                        <input
                          type="text"
                          placeholder="Buscar por título..."
                          value={searchTerm}
                          onChange={handleSearchChange}
                          //style={{ borderColor: selectedColor }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="modal-bodyy">
                    <div className="card-container">
                      {renderPost()}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
  
          <div className="flex items-center seccion-cliente-reg">
            <div className="flex">
              <select
            value={selectedClient}
            onChange={(e) => setSelectedClient(e.target.value)}
            className="input-nombre-cliente"
          >
            <option value="">Seleccionar cliente</option>
            {/* Mapea y muestra los nombres de los clientes */}
            {clientes.map((cliente) => (
              <option key={cliente._id} value={cliente.nombre}>{cliente.nombre}</option>
            ))}
          </select>
            </div>
            <div>
              <CiDeliveryTruck 
              className="icono-opcion-peq" 
              onClick={() => setIsDomicilio(!isDomicilio)} // Toggle el estado
              style={{ backgroundColor: isDomicilio ? 'green' : 'white', color: isDomicilio ? 'white' : 'black' }}
            />
            </div>
            <div>
            <IoIosRefresh
                  className="icono-opcion-peq lg:hidden"
                  onClick={handleClickRefresh}
              />
            </div>
            <div className="lg:hidden">
              <FaCashRegister className="icono-opcion-peq" onClick={openModalProducto} />
            </div>
            <div className="adicionar lg:hidden cursor-pointer text-center" onClick={() => setIsProductModalOpen(!isProductModalOpen)}>
                <AiOutlineAppstoreAdd className="icon-agregar" />
              </div>
          </div>

          <div className="mesas-sm lg:hidden">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map((tableNumber) => (
                <button
                    key={tableNumber}
                    className={`botones-mesa-sm ${selectedTable === tableNumber ? 'active' : ''}`}
                    onClick={() => handleTableChange(tableNumber)}
                    style={{ backgroundColor: selectedTable === tableNumber ? 'black' : 'initial', color: selectedTable === tableNumber ? 'white' : 'initial' }}
                >
                    {tableNumber}
                </button>
            ))}
          </div>
  
          <hr />
  
          <div className="todo-factura">
            <div className="capsula-producto">
              {selectedProducts.length === 0 ? (
                <div className="no-product-selected">
                  <CiGrid41 className="big-icon" /*style={{ color: selectedColor }}*/ />
                  <p className="no-selected" /*style={{ color: selectedColor }}*/>Ningún producto seleccionado</p>
                </div>
              ) : (
                selectedProducts.map((product) => (
                  <div key={product._id} className="informacion-producto">
                    <div className="flex justify-between items-center">
                      <div className="w-1/7">
                      {product.image ? (
                        <img className="image-services-1" src={product.image.url} alt={product.title} />
                      ) : (
                        <FcAddImage className="image-services-1" alt="default" />
                      )}
                      </div>
                      
                        <div className="w-4/7 text-center" /*style={{ backgroundColor: selectedColor }}*/>
                          <div className="titulo-factura">{product.title}</div>
                        </div>
            
                      <div className="text-sm w-2/7">
                        <div className="flex sumar2">
                            <button onClick={() => handleDecrement(product)}>
                              <AiOutlineMinusCircle className="plus" />
                            </button>
                            <div className="mx-2">{getProductQuantity(product)}</div>
                            <button onClick={() => handleIncrement(product)}>
                              <AiOutlinePlusCircle className="plus" />
                            </button>
                          </div>
                          <div className="text-center">
                          ${product.precio * getProductQuantity(product)}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
  
            <div className="opciones-factura">
            {metodosPago.map((pago, index) => (
  <div key={index} className="capsula-metodos">
    <select className="select-metodo" onChange={(e) => handleMetodoPagoChange(index, e.target.value)} value={pago.metodo}>
      <option value="">Seleccione</option>  {/* Añadir una opción por defecto */}
      <option value="efectivo">Efectivo</option>
      <option value="transferencia">Transferencia</option>
      <option value="fiado">Fiado</option>
      <option value="abono">Abono</option>
      <option value="familia">Familia</option>
    </select>
    <input
      className="input-metodo"
      type="number"
      placeholder="Cantidad"
      value={pago.cantidad}
      onChange={(e) => handleCantidadChange(index, e.target.value)}
    />
    <div className="agregar-metodo-pago">
      {index > 0 && (
        <button onClick={() => removeMetodoPago(index)}><AiOutlineMinusCircle className="botones-agregar-metodo" /></button>
      )}
      {index === metodosPago.length - 1 && (
        <button onClick={addMetodoPago}><AiOutlinePlusCircle className="botones-agregar-metodo" /></button>
      )}
    </div>
  </div>
))}


              <div className="capsula-cambios">
              <input
                className="input-recibido"
                type="number"
                id="valorRecibido"
                placeholder="Recibido"
                value={valorRecibido.toLocaleString()}
                onChange={(e) => setValorRecibido(e.target.value)}
              />
              <div className="input-cambio" id="cambio">
                {calculateCambio().toLocaleString()}
              </div>

              </div>
              <div className="capsula-obs">
                <input
                  placeholder="Observación"
                  className="observacion"
                  value={observacion}
                  onChange={(e) => setObservacion(e.target.value)}
                ></input>
              </div>
            </div>
          </div>
  
            <div className="seccion-venderr lg:mt-12">
            {hasValidProductsForKitchen() ? (
            <div className="vender1">
                <button
                  className="flex w-full justify-between"
                  onClick={cocinaSell}
                  disabled={isSelling}
                >
                  <p className="text-factura">COCINA</p>
                </button>
              </div>
            ) : null}
            
            {(!hasKitchenValidProducts() || !showCocinaButton) && (
              <div className="vender2 animated fadeIn">
              {isSelling ? (
                <AiOutlineLoading3Quarters className="spin-icon" />
              ) : (
                <button
                  className="flex w-full justify-between"
                  onClick={handleSell}
                  disabled={isSelling}
                >
                  <p className="text-factura">VENDER</p>
                  <p className="text-factura">${calculateInvoiceTotal()}</p>
                </button>
              )}
            </div>
            )}   
          </div>

        </div>
      </div>
      {showQuantityWarning && (
        <div className="modal-warning">
          No tienes la cantidad suficiente.
          <button onClick={() => setShowQuantityWarning(false)}>Cerrar</button>
        </div>
      )}
      {errorMessage && (
        <div className="modal-warning">
          {errorMessage}
        </div>
      )}
    </div>
  );
}