// AuthContext.js
import React, { createContext, useContext, useState } from 'react';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null); // Información del usuario autenticado
  const [authenticated, setAuthenticated] = useState(false); // Estado de autenticación


  // Función para iniciar sesión
  const login = ({ username, password, cargo, sede }) => {
    if (username) {
      setUser({ username, cargo, sede }); // Guardar el cargo junto con el usuario
      setAuthenticated(true);
    } else {
      // Manejar el caso en que el nombre de usuario no esté definido
      console.error('El nombre de usuario no está definido');
      // Podrías lanzar un error, mostrar un mensaje de error, etc.
    }
  };
  

  // Función para cerrar sesión
  const logout = () => {
    setUser(null);
    setAuthenticated(false);
  };

  return (
    <AuthContext.Provider value={{ user, authenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

