import { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";


const FacturaEmpleadoContext = createContext();

export const useFacturasEmpleados = () => {
  const context = useContext(FacturaEmpleadoContext);
  if (!context) throw new Error("FacturaEmpleado Provider is missing");
  return context;
};

export const FacturaEmpleadoProvider = ({ children }) => {
  const [facturasEmpleados, setFacturasEmpleados] = useState([]);

  const baseURL = "https://server-pos-vkb9.onrender.com/api/facturas-empleado";

  const fetchFacturasEmpleados = async () => {
    try {
      const res = await axios.get(baseURL);
      setFacturasEmpleados(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchFacturasEmpleados();
  }, []);

  const deleteFacturaEmpleado = async (id) => {
    try {
      const res = await axios.delete(`${baseURL}/${id}`);
      if (res.status === 204) {
        setFacturasEmpleados(facturasEmpleados.filter((factura) => factura._id !== id));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const createFacturaEmpleado = async (factura) => {
    try {
      const res = await axios.post(baseURL, factura);
      setFacturasEmpleados([...facturasEmpleados, res.data]);
    } catch (error) {
      console.error(error);
    }
  };

  const getFacturaEmpleado = async (id) => {
    try {
      const res = await axios.get(`${baseURL}/${id}`);
      return res.data;
    } catch (error) {
      console.error(error);
    }
  };

  const updateFacturaEmpleado = async (id, factura) => {
    try {
      const res = await axios.put(`${baseURL}/${id}`, factura);
      setFacturasEmpleados(facturasEmpleados.map((factura) => (factura._id === id ? res.data : factura)));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <FacturaEmpleadoContext.Provider
      value={{
        facturasEmpleados,
        deleteFacturaEmpleado,
        createFacturaEmpleado,
        getFacturaEmpleado,
        updateFacturaEmpleado,
      }}
    >
      {children}
    </FacturaEmpleadoContext.Provider>
  );
};