import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import './Cocina.scss';
import { useCocina } from "../../context/cocinaContext";
import { CocinaList } from "../../components/FacturaList/CocinaList";
import { TbReceiptOff } from "react-icons/tb";

export function Cocina(selectedColor) {
  const currentDate = dayjs();
  const [selectDate] = useState(currentDate);
  const { cocina } = useCocina();
  const [facturasFiltradas, setFacturasFiltradas] = useState([]);

  useEffect(() => {
    const filtradas = cocina.filter(factura => {
      const fechaFactura = dayjs(factura.createdAt);
      return fechaFactura.isSame(selectDate.startOf('day'), 'day');
    });
    setFacturasFiltradas(filtradas);
  }, [cocina, selectDate]);

  const fechaSeleccionadaFormateada = selectDate.toDate().toLocaleString('es-ES', { year: 'numeric', month: 'long', day: '2-digit' });

  return (
    <div className="flex-col todo">
      <h1 className="font-semibold flex header-factura" style={{color: selectedColor}}>
          Almuerzos del {fechaSeleccionadaFormateada}
      </h1>
      <div className="facturas-main sm:px-5">
        {facturasFiltradas.length === 0 ? (
          <div className="" style={{color: selectedColor}}>
            <TbReceiptOff className="icono-nofactura espacio-no" style={{backgroundColor: selectedColor}} />
            <p className="nofactura-letra espacio-no" style={{color: selectedColor}}>Sin facturas en fecha seleccionada</p>
          </div>
        ) : (
          <CocinaList cocina={facturasFiltradas} />
        )}
      </div>
    </div>
  );
}