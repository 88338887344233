import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import './Factura.scss';
import { generateDate, months } from "../../components/Calendar/calendar";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import cn from "../../components/cn/cn";
import { useFacturas } from "../../context/facturaContext";
import { FacturaList } from "../../components/FacturaList/FacturaList";
import { BsCalendar3 } from "react-icons/bs";
import { TbReceiptOff } from "react-icons/tb";

export function Facturas(selectedColor) {
  const days = ["Do", "Lu", "Ma", "Mie", "Ju", "Vi", "Sa"];
  const currentDate = dayjs();
  const [today, setToday] = useState(currentDate);
  const [selectDate, setSelectDate] = useState(currentDate);
  const { facturas, getFactura } = useFacturas(); // Corrección: Obtén `getFacturas` desde el contexto
  const [showCalendario, setShowCalendario] = useState(false);

  const toggleCalendario = () => {
    setShowCalendario(!showCalendario);
  };

  useEffect(() => {
    // Llama a getFactura cuando cambie la dependencia
    getFactura();
  }, [getFactura]);
  

  const facturasFiltradas = facturas.filter(factura => {
    const fechaFactura = dayjs(factura.createdAt);
    return fechaFactura.isSame(selectDate.startOf('day'), 'day');
  });

  // Formatear la fecha seleccionada y la fecha de creación de la factura
  const fechaSeleccionadaFormateada = selectDate.toDate().toLocaleString('es-ES', { year: 'numeric', month: 'long', day: '2-digit' });
  

  return (
    <div className="flex-col todo">
      <h1 className="font-semibold flex header-factura" style={{color: selectedColor}}>
          Facturas del {fechaSeleccionadaFormateada}
          <button className="icon-button" style={{color: selectedColor}}  onClick={toggleCalendario}>
            <BsCalendar3 />
          </button>
        </h1>
      <div className="facturas-main sm:px-5">
        {/* Mostrar el modal del calendario si showCalendario es verdadero */}
        {showCalendario && (
          <div className="modal">
            <div className="modal-content-calendarioo">
              <span className="close-button" onClick={toggleCalendario}>
                &times;
              </span>
              <div className="calendario ">
        <div className="flex justify-between items-center primer-div">
          <h1 className="select-none font-semibold">
            {months[today.month()]}, {today.year()}
          </h1>
          <div className="flex gap-10 items-center ">
            <GrFormPrevious
              className="w-5 h-5 cursor-pointer hover:scale-105 transition-all"
              onClick={() => {
                setToday(today.month(today.month() - 1));
              }}
            />
            <h1
              className=" cursor-pointer hover:scale-105 transition-all"
              onClick={() => {
                setToday(currentDate);
              }}
            >
              Hoy
            </h1>
            <GrFormNext
              className="w-5 h-5 cursor-pointer hover:scale-105 transition-all"
              onClick={() => {
                setToday(today.month(today.month() + 1));
              }}
            />
          </div>
        </div>
        <div className="grid grid-cols-7">
          {days.map((day, index) => {
            return (
              <div className="holaa">
                <h1
                key={index}
                className="hola-text"
                >
                  {day}
                </h1>
              </div>
            );
          })}
        </div>

        <div className=" grid grid-cols-7 hola2">
          {generateDate(today.month(), today.year()).map(
            ({ date, currentMonth, today }, index) => {
              return (
                <div
                  key={index}
                  className="p-2 text-center h-14 grid place-content-center number-calendar border-t"
                >
                  <h1
                    className={cn(
                      currentMonth ? "" : "text-gray-400",
                      today ? " text-white" : "",
                      selectDate.toDate().toDateString() ===
                      date.toDate().toDateString()
                        ? "bg-black text-white"
                        : "",
                      "h-10 w-10 rounded-full grid place-content-center hover:bg-black hover:text-white transition-all cursor-pointer select-none"
                    )}
                    onClick={() => {
                      setSelectDate(date);
                      toggleCalendario();
                    }}
                  >
                    {date.date()}
                  </h1>
                </div>
              );
            }
          )}
        </div>
      </div>
            </div>
          </div>
        )}
        <div className="">
          <div className="" style={{color: selectedColor}}>
            {facturasFiltradas.length === 0 && <TbReceiptOff className="icono-nofactura espacio-no" style={{backgroundColor: selectedColor}} />}
            {facturasFiltradas.length === 0 && <p className="nofactura-letra espacio-no" style={{color: selectedColor}}>Sin facturas en fecha seleccionada</p>}
          </div>
          <FacturaList className="flex" facturas={facturasFiltradas} />
          <div></div>
        </div>
      </div>
    </div>
  );
}