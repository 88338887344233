import React, { useState, useEffect, useCallback } from 'react';
import './Header.scss';
import '../../App.scss';
import { DropdownMenu } from '../DropDown/DropdownMenu';
import { GoTriangleRight } from "react-icons/go";

export function Header({ selectedColor, onColorChange }) {
  const [iconPosition, setIconPosition] = useState({ x: 5, y: 120 });
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [prevMousePosition, setPrevMousePosition] = useState({ x: 0, y: 0 });
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMouseDown = (e) => {
    setIsMouseDown(true);
    setPrevMousePosition({ x: e.clientX, y: e.clientY });
  };

  const handleMouseUp = useCallback(() => {
    setIsMouseDown(false);
  }, []);

  const handleMouseMove = useCallback((e) => {
    if (isMouseDown) {
      const deltaX = e.clientX - prevMousePosition.x;
      const deltaY = e.clientY - prevMousePosition.y;
      const newX = iconPosition.x + deltaX;
      const newY = iconPosition.y + deltaY;
      setIconPosition({ x: newX, y: newY });
      setPrevMousePosition({ x: e.clientX, y: e.clientY });
    }
  }, [isMouseDown, iconPosition, prevMousePosition]);

  const handleTouchStart = useCallback((e) => {
    setIsMouseDown(true);
    const touch = e.touches[0];
    setPrevMousePosition({ x: touch.clientX, y: touch.clientY });
  }, []);

  const handleTouchMove = useCallback((e) => {
    if (isMouseDown) {
      const touch = e.touches[0];
      const deltaX = touch.clientX - prevMousePosition.x;
      const deltaY = touch.clientY - prevMousePosition.y;
      const newX = iconPosition.x + deltaX;
      const newY = iconPosition.y + deltaY;
      setIconPosition({ x: newX, y: newY });
      setPrevMousePosition({ x: touch.clientX, y: touch.clientY });
    }
  }, [isMouseDown, iconPosition, prevMousePosition]);

  const handleTouchEnd = useCallback(() => {
    setIsMouseDown(false);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleMouseMoveEvent = (e) => {
      handleMouseMove(e);
    };

    const handleMouseUpEvent = () => {
      handleMouseUp();
    };

    document.addEventListener('mousemove', handleMouseMoveEvent);
    document.addEventListener('mouseup', handleMouseUpEvent);

    return () => {
      document.removeEventListener('mousemove', handleMouseMoveEvent);
      document.removeEventListener('mouseup', handleMouseUpEvent);
    };
  }, [handleMouseMove, handleMouseUp]);

  useEffect(() => {
    const draggableIcon = document.querySelector('.draggable-icon');
    if (draggableIcon) {
      draggableIcon.addEventListener('touchstart', handleTouchStart);
      draggableIcon.addEventListener('touchmove', handleTouchMove);
      draggableIcon.addEventListener('touchend', handleTouchEnd);

      return () => {
        draggableIcon.removeEventListener('touchstart', handleTouchStart);
        draggableIcon.removeEventListener('touchmove', handleTouchMove);
        draggableIcon.removeEventListener('touchend', handleTouchEnd);
      };
    }
  }, [handleTouchStart, handleTouchMove, handleTouchEnd]);

  return (
    <div className="draggable-container md:w-full">
      <div
        className="draggable-icon"
        style={{ top: iconPosition.y, left: iconPosition.x }}
        onMouseDown={handleMouseDown}
        onClick={toggleMenu} // Abrir/cerrar el menú al hacer clic en el icono
      >
        <GoTriangleRight className='icono-flotante' />
      </div>
      <DropdownMenu isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)}  /> {/* Pasar el estado del menú al componente de menú desplegable */}
    </div>
  );
}