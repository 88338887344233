import React from 'react';
import './Aviso.scss';  // Asegúrate de crear un archivo CSS para los estilos del modal

const Aviso = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Nueva Orden</h2>
                <button onClick={onClose}>OK</button>
            </div>
        </div>
    );
};

export default Aviso;
