import React, { useState } from 'react';
import './ProveedoresCard.scss';
import { useProveedores } from '../../context/proveedoresContext';
import { useFacturasProveedores } from '../../context/facturasProveedoresContext';
import { usePosts } from '../../context/postContext';
//import { useAuth } from '../../context/AuthContext'; 
import { FaCheck } from "react-icons/fa";
import { IoMdRemove } from "react-icons/io";
import { GiHandTruck } from "react-icons/gi";
import { IoBagAddSharp } from "react-icons/io5";
import { ModalComponentProveedor } from '../ModalProveedor/ModalProveedor';
import { useUsuarios } from '../../context/usuarioContext';
import { CgMenuRound } from "react-icons/cg";
import {AiFillCloseCircle } from 'react-icons/ai';

export function ProveedoresCard({ selectedColor }) {
  const { proveedores } = useProveedores();
  const { usuarios } = useUsuarios();
  const { posts, updatePost  } = usePosts();
  const { createFacturaProveedor } = useFacturasProveedores()

  const [cantidades, setCantidades] = useState({});
  const [selectedProveedor, setSelectedProveedor] = useState(null); // Estado para el proveedor seleccionado
  const [selectedProductsInfo, setSelectedProductsInfo] = useState([]); // Estado para la información de los productos seleccionados
  const [searchTerm, setSearchTerm] = useState('');
  const [isModalOpenProducto, setIsModalOpenProducto] = useState(false);
  const [selectedEmpleado, setSelectedEmpleado] = useState('');
  const [isProductModalOpen, setIsProductModalOpen] = useState(false);

  //const { user } = useAuth();


  const closeModalProduct = () => {
    setIsProductModalOpen(false);
  };
  
  const handleFacturar = async () => {
    // Crear el objeto de factura

    if (!selectedProveedor || !selectedEmpleado) {
      alert("Por favor, selecciona un proveedor y un empleado antes de facturar.");
      return; // Detener la ejecución si alguno está nulo
    }

    const factura = {
      nombreProveedor: selectedProveedor,
      vendedor: selectedEmpleado,
      productos: selectedProductsInfo.map(product => ({
        nombre: product.title,
        cantidad: cantidades[product.postId],
        subtotal: product.subtotal
      })),
      cantidadTotal: totalCantidad,
      totalFactura: selectedProductsInfo.reduce((acc, product) => acc + product.subtotal, 0)
    };
  
    // Llamar a la función para guardar la factura
    createFacturaProveedor(factura);
  
    // Actualizar la cantidad en los posts
    selectedProductsInfo.forEach(async (product) => {
      const postToUpdate = posts.find(post => post._id === product.postId);
      const updatedCantidad = (postToUpdate.cantidad || 0) + (cantidades[product.postId] || 0);
      await updatePost(product.postId, { cantidad: updatedCantidad });
    });
  
    // Limpiar los productos seleccionados después de guardar la factura
    setSelectedProductsInfo([]);
  };

  const handleSearch = (searchValue) => {
    setSearchTerm(searchValue);
  };

  const handleChangeProveedor = (e) => {
    setSelectedProveedor(e.target.value); // Actualizar el proveedor seleccionado
  };

  const calcularSubtotal = (postId) => {
    const cantidad = cantidades[postId] || 0;
    const producto = posts.find((post) => post._id === postId);
    return cantidad * producto.precioCompra;
  };

  const actualizarCantidad = (postId, cantidad) => {
    setCantidades({ ...cantidades, [postId]: cantidad });
  };

  const handleSelectProduct = (postId) => {
    // Verificar si el producto ya está seleccionado
    const isProductSelected = selectedProductsInfo.some((product) => product.postId === postId);
    const cantidadIngresada = cantidades[postId];

    if (!isProductSelected && cantidadIngresada) {
      const producto = posts.find((post) => post._id === postId);
      const subtotal = calcularSubtotal(postId);
      const newProductInfo = { postId, title: producto.title, subtotal };
      setSelectedProductsInfo([...selectedProductsInfo, newProductInfo]);
    }
  };

  const handleRemoveProduct = (postId) => {
    setSelectedProductsInfo(selectedProductsInfo.filter((product) => product.postId !== postId));
  };

  // Filtrar los productos por proveedor seleccionado
  const productosFiltrados = selectedProveedor
    ? posts.filter((post) => post.proveedor === selectedProveedor)
    : posts;

const isSelected = (postId) => {
    return selectedProductsInfo.some((product) => product.postId === postId);
};


  const openModalProducto = () => {
    setIsModalOpenProducto(true);
  };

  const closeModalProducto = () => {
    setIsModalOpenProducto(false);
  };

  // Calcular el total y la cantidad de todos los productos seleccionados
  const totalProductos = selectedProductsInfo.reduce((acc, product) => acc + product.subtotal, 0);
  const totalCantidad = selectedProductsInfo.reduce((acc, product) => acc + (cantidades[product.postId] || 0), 0);

  return (
    <div className="container-proveedoress w-full">
      <div className=' hidden lg:block'>
        <div className='header-prov'>
          <h1 className='h1-prov'>Administra tus proveedores</h1>
          <input
            className="input-buscador"
            type="text"
            placeholder="Buscar por título"
            onChange={(e) => handleSearch(e.target.value)}
            style={{ borderColor: selectedColor }}
          />
          <IoBagAddSharp className='add-prov' style={{ color: selectedColor }} onClick={openModalProducto} />
          <div className={{backgroundColor: selectedColor}}>
              <ModalComponentProveedor
                isOpen={isModalOpenProducto}
                onRequestClose={closeModalProducto}
              />
          </div>
        </div>
        <div className="productos-proveedor gap-10">
        {productosFiltrados
        .filter((post) =>
          post.title.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .map((post) => (
          <div key={post._id} className={`producto-cap-proveedores ${isSelected(post._id) ? 'seleccionado-prov' : ''}`} style={{ borderColor: selectedColor }}>
            <h2 className="titulo-producto-pro">{post.title}</h2>
            {post.image && post.image.url && <img className="image-proveedor" src={post.image.url} alt={post.title} />}
            <p className="precio-producto-pro">Precio: ${post.precioCompra}</p>
            <p className="precio-producto-pro">Sede: {post.sede}</p>
            <div className="incrementar-cantidad">
              <input
                className="ingresar-cantidad"
                placeholder="Cantidad"
                type="number"
                value={cantidades[post._id] || ''}
                onChange={(e) => actualizarCantidad(post._id, Number(e.target.value))}
              />
              <p className="total-proovedor-pagar">Subtotal: ${calcularSubtotal(post._id)}</p>
              <FaCheck className='check-pro' onClick={() => handleSelectProduct(post._id)} />
            </div>
          </div>
        ))}

      </div>
      </div>
      <div className="proveedores-factura" style={{ borderColor: selectedColor }}>
        <div className="select-pro">
          <select className='select-pro2' value={selectedProveedor} onChange={handleChangeProveedor}>
            <option value="">Seleccionar Proveedor</option>
            {proveedores.map((proveedor) => (
              <option key={proveedor.id} value={proveedor.id}>
                {proveedor.nombre}
              </option>
            ))}
          </select>
          <select className='select-usuario-prov' value={selectedEmpleado} onChange={(e) => setSelectedEmpleado(e.target.value)}>
            <option value="">Selecciona un empleado</option>
            {/* Renderizar las opciones dentro del select */}
            {usuarios.length > 0 && usuarios[0].empleados.map(empleado => (
              <option key={empleado._id} value={empleado.usuario}>{empleado.usuario}</option>
            ))}
          </select>
          <CgMenuRound className='menu-pro block lg:hidden' onClick={() => setIsProductModalOpen(!isProductModalOpen)} />
          {isProductModalOpen && (
              <div className="Modal">
                <div className="closebutton" onClick={closeModalProduct}>
                  <AiFillCloseCircle />
                </div>
                <div className="modal-contentt">
                  <div className="modal-header">
                    <div className="modal-options">
                      <input
                        className="input-buscador"
                        type="text"
                        placeholder="Buscar por título"
                        onChange={(e) => handleSearch(e.target.value)}
                        style={{ borderColor: selectedColor }}
                      />
                    </div>
                  </div>
                  <div className="modal-bodyy">
                    <div className="card-container">
                    <div className="productos-proveedor gap-10">
        {productosFiltrados
        .filter((post) =>
          post.title.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .map((post) => (
          <div key={post._id} className={`producto-cap-proveedores ${isSelected(post._id) ? 'seleccionado-prov' : ''}`} style={{ borderColor: selectedColor }}>
            <h2 className="titulo-producto-pro">{post.title}</h2>
            {post.image && post.image.url && <img className="image-proveedor" src={post.image.url} alt={post.title} />}
            <p className="precio-producto-pro">Precio: ${post.precioCompra}</p>
            <p className="precio-producto-pro">Sede: {post.sede}</p>
            <div className="incrementar-cantidad">
              <input
                className="ingresar-cantidad"
                placeholder="Cantidad"
                type="number"
                value={cantidades[post._id] || ''}
                onChange={(e) => actualizarCantidad(post._id, Number(e.target.value))}
              />
              <p className="total-proovedor-pagar">Subtotal: ${calcularSubtotal(post._id)}</p>
              <FaCheck className='check-pro' onClick={() => handleSelectProduct(post._id)} />
            </div>
          </div>
        ))}

      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
        </div>
        <div className="capsula-producto-seleccionado">
          {selectedProductsInfo.length === 0 ? (
            <div className="no-product-selected">
              <GiHandTruck className='no-prov'  style={{ color: selectedColor }} />
              <p>No hay productos seleccionados</p>
            </div>
          ) : (
            <div>
              {selectedProductsInfo.map((product, index) => (
                <div key={index} className="info-producto-seleccionado" style={{ borderColor: selectedColor }}>
                  <div className='info-cap'>
                    <div className='p-3'>
                      <p className=''>{product.title}</p>
                      <br/>
                      <p>Subtotal: ${product.subtotal}</p>
                    </div>
                  </div>
                  <div className='opciones-factura-pro' onClick={() => handleRemoveProduct(product.postId)}>
                    <p className='cantidad-pro' >{cantidades[product.postId]} </p>
                    <IoMdRemove  className='trash-pro' />
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
                <div className='guardar-factura-pro'>
          <div className='final-pro' style={{ borderColor: selectedColor }}>${totalProductos}</div>
          <div className='cantidad-final-pro' style={{ borderColor: selectedColor }}>{totalCantidad}</div>
          <button onClick={handleFacturar}>FACTURAR</button>
        </div>
      </div>
    </div>
  );    
}