import React, { useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { NotFoundPage } from './pages';
import { HomePage } from './pages/Services/HomePage';
import { LoginView } from './pages/LoginView/LoginView';
import { PostProvider } from './context/postContext';
import { FacturaProvider } from './context/facturaContext';
import { FacturaProveedorProvider } from './context/facturasProveedoresContext';
import { ProveedorProvider} from './context/proveedoresContext';
import { UsuarioProvider } from './context/usuarioContext';
import { FacturaEmpleadoProvider } from './context/facturasEmpleadoContext';
import { ClienteProvider } from './context/clienteContext';
import { CocinaProvider } from './context/cocinaContext';
import { Inventario } from './pages/Inventario/Inventario';
import { Success } from './pages/Success/success';
import { Facturas } from './pages/Facturas/Facturas';
import { Nomina } from './pages/Nomina/Nomina';
import { Proveedores } from './pages/Proveedores/Proovedores';
import { Cocina } from './pages/Cocina/Cocina';
import { Analitica } from './pages/Analitica/Analitica';
import { VoiceCommand } from './pages/VoiceCommand/VoiceCommand';
import { Metricas } from './pages/Metricas/Metricas';
import { Toaster } from 'react-hot-toast';
import './App.scss';
import { Header } from './components/Header/Header';
import { useAuth } from './context/AuthContext'; // Importa el hook useAuth

function App() {
  const [selectedColor, setSelectedColor] = useState('black');
  const { authenticated, user  } = useAuth();
  const cargo = user ? user.cargo : '';

  const handleColorChange = (color) => {
    setSelectedColor(color);
  };

  return (
    <>
    {/* Renderiza el Header si el usuario está autenticado y no está en la vista LoginView */}
    {authenticated && cargo !== "empleado" && cargo !== "cocinera" && window.location.pathname !== '/' && (
        <Header selectedColor={selectedColor} onColorChange={handleColorChange} />
    )}

      <UsuarioProvider>
        <PostProvider>
          <ProveedorProvider>
            <FacturaProvider>
              <FacturaProveedorProvider>
                <FacturaEmpleadoProvider>
                  <ClienteProvider>
                    <CocinaProvider>
              <div className="min-h-screen flex justify-center items-center Main">
                <Routes>

                  {/* Ruta de LoginView */}
                  <Route
                    path="/"
                    element={!authenticated ? <LoginView /> : <Navigate to="/Cocina" />}
                  />
                  
                  {/* Rutas protegidas que requieren autenticación */}
                  <Route
                    path="/services"
                    element={authenticated ? <HomePage selectedColor={selectedColor} onColorChange={handleColorChange} /> : <Navigate to="/" />}
                  />
                  <Route
                    path="/Inventario"
                    element={authenticated ? <Inventario selectedColor={selectedColor} onColorChange={handleColorChange} /> : <Navigate to="/" />}
                  />
                  <Route
                    path="/Proveedores"
                    element={authenticated ? <Proveedores selectedColor={selectedColor} onColorChange={handleColorChange} /> : <Navigate to="/" />}
                  />
                  <Route
                    path="/Facturas"
                    element={authenticated ? <Facturas selectedColor={selectedColor} onColorChange={handleColorChange} /> : <Navigate to="/" />}
                  />
                  <Route
                    path="/Cocina"
                    element={authenticated ? <Cocina /> : <Navigate to="/" />}
                    />
                  <Route
                    path="/Nomina"
                    element={authenticated ? <Nomina selectedColor={selectedColor} /> : <Navigate to="/" />}
                  />
                  <Route
                    path="/Analitica"
                    element={authenticated ? <Analitica selectedColor={selectedColor} /> : <Navigate to="/" />}
                  />
                  <Route
                    path="/Voice"
                    element={authenticated ? <VoiceCommand selectedColor={selectedColor} /> : <Navigate to="/" />}
                  />
                  <Route
                    path="/Metricas"
                    element={authenticated ? <Metricas selectedColor={selectedColor} /> : <Navigate to="/" />}
                  />

                  <Route
                    path="/Success"
                    element={<Success />}
                  />
                  
                  {/* Ruta predeterminada para mostrar una página no encontrada */}
                  <Route path="/" element={<NotFoundPage />} />
                </Routes>
                <Toaster />
              </div>
                    </CocinaProvider>
                  </ClienteProvider>
                </FacturaEmpleadoProvider>
              </FacturaProveedorProvider>
            </FacturaProvider>
          </ProveedorProvider>
        </PostProvider>
      </UsuarioProvider>
    </>
  );
}

export default App;
