import React, { useState } from 'react';
import './FacturaList.scss';
import { IoSendSharp } from "react-icons/io5";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { SiGmail } from "react-icons/si";
import { MdDownloadForOffline } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";
import { useFacturas } from '../../context/facturaContext'; // Asegúrate de que esta importación sea correcta
import { MdEdit } from "react-icons/md";
import { FaClipboardCheck } from "react-icons/fa";

export function FacturaList({ facturas }) {
  const { updateFactura } = useFacturas();
  const [expandedFactura, setExpandedFactura] = useState(null);
  const [editingFactura, setEditingFactura] = useState(null);
  const [editValues, setEditValues] = useState({ total: '', metodosPago: [] });

  const handleExpand = (id) => {
    setExpandedFactura(id);
  };

  const handleShrink = () => {
    setExpandedFactura(null);
  };

  const handleEdit = (factura) => {
    setEditingFactura(factura._id);
    setEditValues({
      total: factura.totalFactura,
      metodosPago: factura.metodosPago.map(metodo => ({ metodo: metodo.metodo, cantidad: metodo.cantidad }))
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditValues({ ...editValues, [name]: value });
  };

  const handleMetodoPagoChange = (index, field, value) => {
    const newMetodosPago = [...editValues.metodosPago];
    newMetodosPago[index][field] = value;
    setEditValues({ ...editValues, metodosPago: newMetodosPago });
  };

  const handleSave = async (id) => {
    const updatedFactura = {
      ...facturas.find(factura => factura._id === id),
      totalFactura: editValues.total,
      metodosPago: editValues.metodosPago
    };

    await updateFactura(id, updatedFactura);
    setEditingFactura(null);
    setEditValues({ total: '', metodosPago: [] });
  };

  const generatePDF = (factura) => {
    const pdf = new jsPDF();

    pdf.setFontSize(18);
    pdf.text('Jengibre y Apio', 20, 20);
    pdf.setFontSize(12);
    pdf.text('restaurante', 20, 30);

    pdf.text(`Fecha: ${new Date(factura.createdAt).toLocaleString('es-ES', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })}`, 20, 50);
    pdf.text(`Cliente: ${factura.cliente}`, 20, 60);
    pdf.text(`Vendedor: ${factura.vendedor}`, 20, 70);

    const productos = factura.productos.map(producto => [
      producto.nombre, producto.cantidad, producto.precio, producto.total
    ]);

    pdf.autoTable({
      startY: 80,
      head: [['Nombre', 'Cantidad', 'Vl Unit', 'Valor']],
      body: productos,
    });

    pdf.text(`Subtotal: $${factura.totalFactura}`, 20, pdf.lastAutoTable.finalY + 20);
    pdf.text(`Total: $${factura.totalFactura}`, 20, pdf.lastAutoTable.finalY + 30);

    pdf.text('Formas de pago', 20, pdf.lastAutoTable.finalY + 50);
    factura.metodosPago.forEach((metodo, index) => {
      pdf.text(`${metodo.metodo}: $${metodo.cantidad}`, 20, pdf.lastAutoTable.finalY + 60 + index * 10);
    });

    pdf.text(`Cambio: $${factura.dineroDevuelto}`, 20, pdf.lastAutoTable.finalY + 90);

    pdf.setFontSize(10);
    pdf.text('powered by Mercurio | En Orbita', 20, pdf.lastAutoTable.finalY + 110);
    pdf.text('Fabricante del software', 20, pdf.lastAutoTable.finalY + 120);

    return pdf;
  };

  const handleSendInvoice = async (factura) => {
    const pdf = generatePDF(factura);
    const pdfBase64 = pdf.output('datauristring').split(',')[1];

    const formData = new FormData();
    formData.append('pdf', pdfBase64);
    formData.append('email', 'vmarrerop@gmail.com');

    try {
      const response = await fetch('http://localhost:3001/api/enviar-factura', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: 'vmarrerop@gmail.com',
          pdf: pdfBase64
        })
      });

      if (response.ok) {
        alert('Factura enviada con éxito!');
      } else {
        const errorText = await response.text();
        console.error('Error en el servidor:', errorText);
        alert('Error al enviar la factura: ' + errorText);
      }
    } catch (error) {
      console.error('Error al enviar la solicitud:', error);
      alert('Error al enviar la factura: ' + error.toString());
    }
  };

  const handleDownloadInvoice = (factura) => {
    const pdf = generatePDF(factura);
    pdf.save('factura.pdf');
  };

  const handleSendWhatsApp = (factura) => {
    const phone = '573222827733';
    const message = `Hola, adjunto encontrarás tu factura.\n\n${facturaDetails(factura)}`;
    const link = generateWhatsAppLink(phone, message);
    window.open(link, '_blank');
  };

  const generateWhatsAppLink = (phone, message) => {
    return `https://wa.me/${phone}?text=${encodeURIComponent(message)}`;
  };

  const facturaDetails = (factura) => {
    let details = `Factura de Jengibre y Apio\n`;
    details += `Fecha: ${new Date(factura.createdAt).toLocaleString('es-ES', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })}\n`;
    details += `Cliente: ${factura.cliente}\n`;
    details += `Vendedor: ${factura.vendedor}\n\n`;
    details += `Productos:\n`;
    factura.productos.forEach(producto => {
      details += `${producto.nombre} - Cantidad: ${producto.cantidad} - Precio: ${producto.precio} - Total: ${producto.total}\n`;
    });
    details += `\nSubtotal: $${factura.totalFactura}\n`;
    details += `Total: $${factura.totalFactura}\n`;
    details += `Formas de pago: ${factura.metodosPago.map(m => `${m.metodo}: $${m.cantidad}`).join(', ')}\n`;
    details += `Cambio: $${factura.dineroDevuelto}\n`;
    return details;
  };

  return (
    <div className="factura-list-container" onClick={handleShrink}>
        {expandedFactura && <div className="overlay-facturas" onClick={handleShrink}></div>}
      <div className="factura-list grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-4">
        {facturas.map((factura) => (
          <div 
            id={`factura-${factura._id}`}
            key={factura._id} 
            className={`factura-item ${expandedFactura === factura._id ? 'expand' : ''}`} 
            onClick={(e) => e.stopPropagation()}
          >
            <h1 className='titulo-restaurante'>Jengibre y Apio</h1>
            <p className='p-sede'>{factura.sede}</p>
            <hr className='linea-factura' />
            <div className='cliente-seccion'>
              <h5>Fecha: {new Date(factura.createdAt).toLocaleString('es-ES', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })} </h5>
              <h5>Cliente: {factura.cliente}</h5>
              <h5>Vendedor: {factura.vendedor}</h5>
            </div>
            <hr className='linea-factura' />
            <div className='productos-factura'>
              <table>
                <thead>
                  <tr className='header-tabla'>
                    <th>Nombre</th>
                    <th>Cantidad</th>
                    <th>Vl Unit</th>
                    <th>Valor</th>
                  </tr>
                </thead>
                <tbody className='header-body'>
                  {factura.productos.map((producto, index) => (
                    <tr key={index}>
                      <td>{producto.nombre}</td>
                      <td>{producto.cantidad}</td>
                      <td>{producto.precio}</td>
                      <td>{producto.total}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <hr className='linea-factura' />
            <div className='seccion-derecha'>
              {editingFactura === factura._id ? (
                <>
                  <h1>Total</h1>
                  <input
                    type="text"
                    name="total"
                    value={editValues.total}
                    onChange={handleInputChange}
                    className='input-factura'
                  />
                  <p>Formas de pago</p>
                  {editValues.metodosPago.map((metodo, index) => (
                    <div key={index} className='metodo-pago'>
                      <input
                        type="text"
                        value={metodo.metodo}
                        onChange={(e) => handleMetodoPagoChange(index, 'metodo', e.target.value)}
                        className='input-factura'
                      />
                      <input
                        type="text"
                        value={metodo.cantidad}
                        onChange={(e) => handleMetodoPagoChange(index, 'cantidad', e.target.value)}
                        className='input-factura'
                      />
                    </div>
                  ))}
                  <div className='icono-editar'>
                    <FaClipboardCheck  onClick={() => handleSave(factura._id)} className='icono-enviar3'></FaClipboardCheck >
                  </div>
                </>
              ) : (
                <>
                  <p>Subtotal: ${factura.totalFactura}</p>
                  <p>Total: ${factura.totalFactura}</p>
                  <p>Formas de pago</p>
                  {factura.metodosPago && factura.metodosPago.map((metodo, index) => (
                    <p className='mt-1 mb-2' key={index}>{metodo.metodo}: ${metodo.cantidad}</p>
                  ))}
                  <div className='icono-editar'>
                    <MdEdit onClick={() => handleEdit(factura)} className='icono-enviar2' ></MdEdit >
                  </div>
                </>
              )}
            </div>
            <footer>
              <p>powered by Train | CAJA MAESTRA</p>
              <p>Fabricante del software</p>
            </footer>
            {expandedFactura === factura._id ? (
              <>
                <div className="iconos-enviar">
                    <SiGmail 
                    className="btn-enviar-factura" 
                    onClick={() => handleSendInvoice(factura)}
                    >         
                    </SiGmail>
                    <MdDownloadForOffline 
                    className="btn-enviar-factura" 
                    onClick={() => handleDownloadInvoice(factura)}
                    >
                    Descargar Factura
                    </MdDownloadForOffline>
                    <FaWhatsapp 
                    className="btn-enviar-factura" 
                    onClick={() => handleSendWhatsApp(factura)}
                    >
                    Enviar por WhatsApp
                    </FaWhatsapp>
                </div>
              </>
            ) : (
              <div className="icono-enviar" onClick={(e) => { e.stopPropagation(); handleExpand(factura._id); }}>
                <IoSendSharp className='icono-enviar2' />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
