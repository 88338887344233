import React from 'react';
import './Success.css';
import { IoMdCheckmarkCircleOutline } from "react-icons/io";

export function ModalSuc({ onClose }) {
  return (
    <div className="modal-overlay-suc">
      <div className="modal-container-suc">
        <IoMdCheckmarkCircleOutline className="modal-check-icon" />
        <h2>Empresa creada!</h2>
        <button className="modal-button-suc" onClick={onClose}>Ir al login</button>
      </div>
    </div>
  );
}
