import React, { useState } from 'react';
import { useNavigate} from 'react-router-dom';
import { useUsuarios } from '../../context/usuarioContext';
import './Success.css';
import { GrFormNextLink } from "react-icons/gr";
import { MdOutlineDelete } from "react-icons/md";
import { renderToString } from 'react-dom/server';
import { LiaShoppingBagSolid } from "react-icons/lia";
import ProgressBar from './ProgressBar';
import { IoMdArrowBack } from "react-icons/io";
import { ModalSuc } from './ModalSuc';

export function Success() {
  const [step, setStep] = useState(1);
  const [companyName, setCompanyName] = useState("");
  const [sedes, setSedes] = useState([{ nombre: "", base: 0 }]);
  const [empleados, setEmpleados] = useState([{
    nombre: "",
    cedula: "",
    direccion: "",
    celular: "",
    usuario: "",
    contraseña: "",
    cargo: "",
    sede: "",
    horaOrdinaria: 0,
    horaDominical: 0,
    horaFestivo: 0,
  }]);

  const { createUsuario } = useUsuarios();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const handleModalClose = () => {
    setShowModal(false);
    navigate('/');
  };

  const iconBackground = (color = 'black') => {
    const svgString = encodeURIComponent(renderToString(<LiaShoppingBagSolid style={{ color }} />));
    return `url("data:image/svg+xml,${svgString}")`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (step === 1 && !companyName) {
      alert("Please enter a company name");
      return;
    }

    if (step === 3) {
      try {
        const newCompany = {
          nombre: companyName,
          sedes,
          empleados,
        };

        await createUsuario(newCompany);
        setShowModal(true);
      } catch (error) {
        console.error("Error creating company:", error);
        alert("There was an error creating the company. Please try again.");
      }
    } else {
      setStep(step + 1);
    }
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  const handleAddSede = () => {
    setSedes([...sedes, { nombre: "", base: 0 }]);
  };

  const handleSedeChange = (index, field, value) => {
    const newSedes = [...sedes];
    newSedes[index][field] = value;
    setSedes(newSedes);
  };

  const handleRemoveSede = (index) => {
    const newSedes = [...sedes];
    newSedes.splice(index, 1);
    setSedes(newSedes);
  };

  const handleAddEmpleado = () => {
    setEmpleados([...empleados, {
      nombre: "",
      cedula: "",
      direccion: "",
      celular: "",
      usuario: "",
      contraseña: "",
      cargo: "",
      sede: "",
      horaOrdinaria: 0,
      horaDominical: 0,
      horaFestivo: 0,
    }]);
  };

  const handleEmpleadoChange = (index, field, value) => {
    const newEmpleados = [...empleados];
    newEmpleados[index][field] = value;
    setEmpleados(newEmpleados);
  };

  const handleRemoveEmpleado = (index) => {
    const newEmpleados = [...empleados];
    newEmpleados.splice(index, 1);
    setEmpleados(newEmpleados);
  };

  const renderStepInfo = () => {
    switch (step) {
      case 1:
        return (
          <div className="div-circles-suc">
            <div className="circle-logo"></div>
            <div className="circle-logo2"></div>
            <div className="circle-logo3"></div>
          </div>
        );
      case 2:
        return (
          <div className='p-5'>
            <h1 className='text-5xl mb-5'>REGISTRA TUS SEDES</h1>
            <h3 className='text-black mb-7'>Ingresa la información de las sedes en los campos correspondientes</h3>
            <h3 className='text-black mb-5'>Campos Requeridos: </h3>
            <p className='text-black mb-3'>Nombre: Reconoce tu sede bajo un nombre</p>
            <p className='text-black'>Valor de Caja: Digita la cantidad de dinero que debería tener la caja en la sede (este valor lo podrás modificar más adelante)</p>
          </div>
        );
      case 3:
        return (
          <div className='p-5'>
            <h1 className='text-4xl mb-5'>CREEMOS LOS EMPLEADOS</h1>
            <h3 className='text-black mb-7'>Ingresa la información de los empleados en los campos correspondientes</h3>
            <h3 className='text-black mb-5'>Campos Requeridos: </h3>
            <p className='text-black mb-3'>Nombre: Ingrese el nombre del empleado</p>
            <p className='text-black mb-3'>Cargo: Ingrese el cargo del empleado</p>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="main-success w-full flex">
      <ProgressBar step={step} totalSteps={3} />
      <p className='creando-empresa'>Creando tu empresa ...</p>
      <div className="hidden lg:flex justify-center items-center w-3/5">
        <div className="lado-izquierdo-suc">
          {renderStepInfo()}
        </div>
      </div>

      <div className="container bg-red-500 w-2/5">
        <form onSubmit={handleSubmit}>
          {step === 1 && (
            <div className="step step-active">
              <h1 className='text-black text-5xl mb-9' htmlFor="companyName">¿Cómo se llama tu empresa?</h1>
              <div>
                <input
                  type="text"
                  id="companyName"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  required
                  className='input-nombre-emp mb-7'
                />
              </div>
              <div className='flex justify-center'>
                <button type="submit" className='buton-next text-white flex justify-center align-center text-center'>
                  <GrFormNextLink className="text-white" />
                </button>
              </div>
            </div>
          )}

          {step === 2 && (
            <div className="step step-active">
              <h2 className='text-black overline text-4xl mb-2'>Sedes</h2>
              <div className="step-container">
                {sedes.map((sede, index) => (
                  <div
                    className='contenedor-sede2'
                    key={index}
                    style={{ backgroundImage: iconBackground('gray') }}
                  >
                    <div className="dentro-casa">
                      <p className='text-black'>Sede Nombre</p>
                      <input
                        type="text"
                        id={`sedeNombre${index}`}
                        value={sede.nombre}
                        onChange={(e) => handleSedeChange(index, 'nombre', e.target.value)}
                        required
                        className='input-nombre-sed mb-2'
                      />
                      <p className='text-black' htmlFor={`sedeBase${index}`}>Valor de caja</p>
                      <input
                        type="number"
                        id={`sedeBase${index}`}
                        value={sede.base}
                        onChange={(e) => handleSedeChange(index, 'base', parseInt(e.target.value))}
                        required
                        className='input-nombre-sed'
                      />
                    </div>
                    {index > 0 && (
                      <button type="button" onClick={() => handleRemoveSede(index)}><MdOutlineDelete className='boton-eliminar-suc' /></button>
                    )}
                  </div>
                ))}
              </div>
              <div className='secciones-varios'>
                <button className='agregar-suc' type="button" onClick={handleAddSede}>Agregar Sede</button>
                <div className='flex justify-between botones-back'>
                  <button type="button" className='buton-back ' onClick={handleBack}>
                    <IoMdArrowBack />
                  </button>
                  <button type="submit" className='buton-next text-white flex justify-center align-center text-center'>
                    <GrFormNextLink />
                  </button>
                </div>
              </div>
            </div>
          )}

          {step === 3 && (
            <div className="step step-active">
              <h2 className='text-black text-4xl mb-2 mt-5'>Usuarios</h2>
              {empleados.map((empleado, index) => (
                <div className='contenedor-sede' key={index}>
                  <div className='lg:flex justify-center align-center'>
                    <div className=''>
                      <label htmlFor={`empleadoNombre${index}`}>Nombre:</label>
                      <input
                        type="text"
                        id={`empleadoNombre${index}`}
                        value={empleado.nombre}
                        onChange={(e) => handleEmpleadoChange(index, 'nombre', e.target.value)}
                        required
                        className='input-usuario'
                      />
                    </div>
                  </div>

                  <div className='lg:flex justify-between align-center'>
                    <div>
                      <label htmlFor={`empleadoCedula${index}`}>Cédula:</label>
                      <input
                        type="text"
                        id={`empleadoCedula${index}`}
                        value={empleado.cedula}
                        onChange={(e) => handleEmpleadoChange(index, 'cedula', e.target.value)}
                        required
                        className='input-usuario'
                      />
                    </div>
                    <div>
                      <label htmlFor={`empleadoDireccion${index}`}>Dirección:</label>
                      <input
                        type="text"
                        id={`empleadoDireccion${index}`}
                        value={empleado.direccion}
                        onChange={(e) => handleEmpleadoChange(index, 'direccion', e.target.value)}
                        required
                        className='input-usuario'
                      />
                    </div>
                    <div>
                      <label htmlFor={`empleadoCelular${index}`}>Celular:</label>
                      <input
                        type="text"
                        id={`empleadoCelular${index}`}
                        value={empleado.celular}
                        onChange={(e) => handleEmpleadoChange(index, 'celular', e.target.value)}
                        required
                        className='input-usuario'
                      />
                    </div>
                  </div>

                  <div className='lg:flex justify-around align-center'>
                    <div>
                      <label htmlFor={`empleadoCargo${index}`}>Cargo:</label>
                      <select
                        id={`empleadoCargo${index}`}
                        value={empleado.cargo}
                        onChange={(e) => handleEmpleadoChange(index, 'cargo', e.target.value)}
                        required
                        className='input-usuario'
                      >
                        <option value="" disabled>Seleccione un cargo</option>
                        <option value="empleado">Empleado</option>
                        <option value="administrador">Administrador</option>
                        <option value="cocinera">Cocinera</option>
                      </select>
                    </div>
                    <div>
                      <label htmlFor={`empleadoSede${index}`}>Sede:</label>
                      <select
                        id={`empleadoSede${index}`}
                        value={empleado.sede}
                        onChange={(e) => handleEmpleadoChange(index, 'sede', e.target.value)}
                        required
                        className='input-usuario'
                      >
                        <option value="" disabled>Seleccione una sede</option>
                        {sedes.map((sede, sedeIndex) => (
                          <option key={sedeIndex} value={sede.nombre}>{sede.nombre}</option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className='lg:flex justify-around align-center'>
                    <div>
                      <label htmlFor={`empleadoUsuario${index}`}>Usuario:</label>
                      <input
                        type="text"
                        id={`empleadoUsuario${index}`}
                        value={empleado.usuario}
                        onChange={(e) => handleEmpleadoChange(index, 'usuario', e.target.value)}
                        required
                        className='input-usuario'
                      />
                    </div>
                    <div>
                      <label htmlFor={`empleadoContrasena${index}`}>Contraseña:</label>
                      <input
                        type="password"
                        id={`empleadoContrasena${index}`}
                        value={empleado.contraseña}
                        onChange={(e) => handleEmpleadoChange(index, 'contraseña', e.target.value)}
                        required
                        className='input-usuario'
                      />
                    </div>
                  </div>

                  <div className='lg:flex justify-around align-center'>
                    <div>
                      <label htmlFor={`empleadoHoraOrdinaria${index}`}>Hora Ordinaria:</label>
                      <input
                        type="number"
                        id={`empleadoHoraOrdinaria${index}`}
                        value={empleado.horaOrdinaria}
                        onChange={(e) => handleEmpleadoChange(index, 'horaOrdinaria', parseInt(e.target.value))}
                        required
                        className='input-usuario'
                      />
                    </div>
                    <div>
                      <label htmlFor={`empleadoHoraDominical${index}`}>Hora Dominical:</label>
                      <input
                        type="number"
                        id={`empleadoHoraDominical${index}`}
                        value={empleado.horaDominical}
                        onChange={(e) => handleEmpleadoChange(index, 'horaDominical', parseInt(e.target.value))}
                        required
                        className='input-usuario'
                      />
                    </div>
                    <div>
                      <label htmlFor={`empleadoHoraFestivo${index}`}>Hora Festivo:</label>
                      <input
                        type="number"
                        id={`empleadoHoraFestivo${index}`}
                        value={empleado.horaFestivo}
                        onChange={(e) => handleEmpleadoChange(index, 'horaFestivo', parseInt(e.target.value))}
                        required
                        className='input-usuario'
                      />
                    </div>
                  </div>

                  {index > 0 && (
                    <button type="button" onClick={() => handleRemoveEmpleado(index)}><MdOutlineDelete className='boton-eliminar-suc' /></button>
                  )}
                </div>
              ))}

              <div className='secciones-varios'>
                <button type="button" className='agregar-suc' onClick={handleAddEmpleado}>Add Empleado</button>
                <div className='flex justify-between terminar-emp'>
                  <button type="button" className='buton-back' onClick={handleBack}>
                    <IoMdArrowBack />
                  </button>
                  <button type="submit" className='buton-crear-empresa text-white flex justify-center align-center text-center'>
                    Crear Empresa
                  </button>
                </div>
              </div>
            </div>
          )}
        </form>
      </div>
      {showModal && <ModalSuc onClose={handleModalClose} />}
    </div>
  );
}
