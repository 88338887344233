import { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";

const ClienteContext = createContext();

export const useClientes = () => {
  const context = useContext(ClienteContext);
  if (!context) throw new Error("ClienteProvider is missing");
  return context;
};

export const ClienteProvider = ({ children }) => {
  const [clientes, setClientes] = useState([]);
  const baseURL = "https://server-pos-vkb9.onrender.com/api/clientes"; // Cambia la URL base según tu configuración

  const fetchClientes = async () => {
    try {
      const res = await axios.get(baseURL);
      setClientes(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchClientes();
  }, []);

  const deleteCliente = async (id) => {
    try {
      const res = await axios.delete(`${baseURL}/${id}`);
      if (res.status === 204) {
        setClientes(clientes.filter((cliente) => cliente._id !== id));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const createCliente = async (cliente) => {
    try {
      const res = await axios.post(baseURL, cliente);
      setClientes([...clientes, res.data]);
    } catch (error) {
      console.error(error);
    }
  };

  const getCliente = async (id) => {
    try {
      const res = await axios.get(`${baseURL}/${id}`);
      return res.data;
    } catch (error) {
      console.error(error);
    }
  };

  const updateCliente = async (id, cliente) => {
    try {
      const res = await axios.put(`${baseURL}/${id}`, cliente);
      setClientes(clientes.map((c) => (c._id === id ? res.data : c)));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ClienteContext.Provider
      value={{ clientes, deleteCliente, createCliente, getCliente, updateCliente }}
    >
      {children}
    </ClienteContext.Provider>
  );
};