import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { usePosts } from "../../context/postContext";
import { useNavigate } from "react-router-dom";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import './Modal.scss';
import { useProveedores } from '../../context/proveedoresContext';
import Select from 'react-select';

Modal.setAppElement('#root');

export function ModalComponent({ isOpen, onRequestClose, selectedProduct }) {
  const { createPost, updatePost, posts } = usePosts();
  const navigate = useNavigate();
  const { proveedores } = useProveedores();
  const [insumos, setInsumos] = useState([]);

  const optionsUnidades = [
    { value: 'Unidad', label: 'Unidad' },
    { value: 'Porcion', label: 'Porción' },
    { value: 'Litro', label: 'Litro' },
    { value: 'Kilogramo', label: 'Kilogramo' }
  ];

  const optionsCategorias = [
    { value: 'Comida', label: 'Comida' },
    { value: 'Insumo', label: 'Insumo' },
    { value: 'Bebida', label: 'Bebida' },
    { value: 'Golosina', label: 'Golosina' },
    { value: 'Desayuno', label: 'Desayuno' },
    { value: 'Almuerzo', label: 'Almuerzo' },
    { value: 'Cliente-Especial', label: 'Cliente Especial' }
  ];

  useEffect(() => {
    const insumosFiltrados = posts.filter(post => post.categoria === 'Insumo');
    setInsumos(insumosFiltrados);
  }, [posts]);

  const optionsInsumos = insumos.map(insumo => ({
    value: insumo._id,
    label: insumo.title
  }));

  const proveedorOptions = (proveedores) => 
    proveedores.map((proveedor) => ({
      value: proveedor.nombre,
      label: proveedor.nombre
    }));

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Ejemplo de Modal"
      className="modal-overlay"
    >
      <div className="ContainerModal w-full sm:w-2/3 md:w-1/3">
        <div className="flex items-center justify-center">
          <div className="">
            <header className="flex justify-between items-center py-4 font-bold">
              <h3 className="text-xl titulo overline">
                {selectedProduct ? 'Editar producto' : 'Nuevo producto'}
              </h3>
              <button onClick={onRequestClose} className="text-atras">
                X
              </button>
            </header>
            <Formik
              initialValues={{
                title: selectedProduct ? selectedProduct.title : '',
                description: selectedProduct ? selectedProduct.description : '',
                precio: '',
                precioCompra: '',
                unidad: '',
                cantidad: '',
                cantidadPorcion: '',
                categoria: '',
                proveedor: '',
                sede: '',
                insumos: selectedProduct ? selectedProduct.insumos.map(insumo => ({
                  value: insumo._id,
                  label: insumo.title
                })) : []
              }}
              enableReinitialize
              validationSchema={Yup.object({
                title: Yup.string().required('Titulo es requerido'),
                unidad: Yup.string().required('Unidad es requerida'),
                precio: Yup.string().required('Precio es requerido'),
                precioCompra: Yup.string().required('Precio de compra es requerido'),
                categoria: Yup.string().required('Categoría es requerido'),
                proveedor: Yup.string().required('Proveedor es requerido'),
                sede: Yup.string().required('Sede es requerido'),
              })}
              onSubmit={async (values, actions) => {
                // Imprimir los valores antes de enviarlos al backend
                console.log("Datos enviados:", values);

                if (selectedProduct) {
                  await updatePost(selectedProduct._id, values);
                } else {
                  await createPost(values);
                }

                actions.resetForm();
                actions.setSubmitting(false);
                onRequestClose();
                navigate('/Inventario');
              }}
            >
              {({ setFieldValue, isSubmitting, handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <label htmlFor="title" className="Titulo">
                    Nombre del producto
                  </label>
                  <Field className="Inputt" placeholder="Nombre del producto" name="title" />
                  <ErrorMessage component="p" name="title" className="text-error" />

                  <label htmlFor="precio" className="Titulo">
                    Precio
                  </label>
                  <Field type="number" className="Inputt" placeholder="Precio del producto" name="precio" />
                  <ErrorMessage component="p" name="precio" className="text-error" />

                  <label htmlFor="precioCompra" className="Titulo">
                    Precio de Compra
                  </label>
                  <Field type="number" className="Inputt" placeholder="Precio del producto" name="precioCompra" />
                  <ErrorMessage component="p" name="precioCompra" className="text-error" />

                  <div className='flex w-full unidad-porcion'>
                    <div className='w-1/2'>
                    <label htmlFor="unidad" className="Titulo">
                      Unidad
                    </label>
                    <Select
                      name="unidad"
                      className="select-unidad"
                      options={optionsUnidades}
                      onChange={(selected) => setFieldValue("unidad", selected.value)}
                    />
                    <ErrorMessage component="p" name="unidad" className="text-error" />
                    </div>


                    <div className='w-1/2'>
                    <label htmlFor="cantidadPorcion" className="Titulo">
                    Cantidad de la porción
                  </label>
                  <Field type="number" className="InputPorcion" placeholder="Unidad de porcion" name="cantidadPorcion" />
                  <ErrorMessage component="p" name="cantidaPorcion" className="text-error" />
                    </div>
                  </div>

                  <label htmlFor="cantidad" className="Titulo">
                    Cantidad
                  </label>
                  <Field type="number" className="Inputt" placeholder="Cantidad en inventario" name="cantidad" />
                  <ErrorMessage component="p" name="cantidad" className="text-error" />

                  <label htmlFor="description" className="Titulo">
                    Descripción
                  </label>
                  <Field
                    name="description"
                    id="description"
                    placeholder="Descripcion del producto"
                    rows="3"
                    className="Inputt"
                  />
                  <ErrorMessage component="p" name="description" className="text-error" />

                  <label htmlFor="insumos" className="Titulo">Insumos</label>
                  <Select
                    options={optionsInsumos}
                    isMulti
                    name="insumos"
                    onChange={(selected) => {
                      const selectedInsumos = selected.map(option => ({
                        nombreInsumo: option.label,
                        cantidadInsumo: 1
                      }));
                      setFieldValue('insumos', selectedInsumos);
                    }}
                  />
                  <ErrorMessage component="p" name="insumos" className="text-error" />

                  <label htmlFor="sede" className="Titulo">
                    Sede
                  </label>
                  <Field
                    name="sede"
                    id="sede"
                    placeholder="Sede del producto"
                    rows="3"
                    className="Inputt"
                  />
                  <ErrorMessage component="p" name="sede" className="text-error" />

                  <label htmlFor="categoria" className="Titulo">
                    Categoría
                  </label>
                  <Select
                    name="categoria"
                    className="select-unidad"
                    options={optionsCategorias}
                    onChange={(selected) => setFieldValue("categoria", selected.value)}
                  />
                  <ErrorMessage component="p" name="categoria" className="text-error" />

                  <label htmlFor="proveedor" className="Titulo">
                    Proveedor
                  </label>
                  <Select
                    name="proveedor"
                    options={proveedorOptions(proveedores)}
                    className=""
                    onChange={(selected) => setFieldValue("proveedor", selected.value)}
                    placeholder="Seleccionar..."
                  />
                  <ErrorMessage component="p" name="proveedor" className="text-error" />

                  <label htmlFor="image" className="Titulo">
                    Imagen
                  </label>
                  <input
                    type="file"
                    name="image"
                    className="Inputt"
                    onChange={(e) => setFieldValue('image', e.target.files[0])}
                  />
                  <ErrorMessage component="p" name="image" className="text-red-400 text-sm" />

                  <button type="submit" className="guardar" disabled={isSubmitting}>
                    {isSubmitting ? (
                      <AiOutlineLoading3Quarters className="animate-spin h-5 w-5" />
                    ) : (
                      'Guardar'
                    )}
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  );
}
