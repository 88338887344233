import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";

const CocinaContext = createContext();

export const useCocina = () => {
  const context = useContext(CocinaContext);
  if (!context) throw new Error("Cocina Provider is missing");
  return context;
};

export const CocinaProvider = ({ children }) => {
  const [cocina, setCocina] = useState([]);
  const baseURL = "https://server-pos-vkb9.onrender.com/api/cocinas";

  useEffect(() => {
    const fetchCocina = async () => {
      try {
        const response = await axios.get(baseURL);
        if (Array.isArray(response.data)) {
          setCocina(response.data);
        } else {
          console.error("Received non-array response data:", response.data);
        }
      } catch (error) {
        console.error("Failed to fetch cocina:", error);
      }
    };

    fetchCocina();

    const eventSource = new EventSource("https://server-pos-vkb9.onrender.com/events");
    eventSource.onmessage = event => {
      const newData = JSON.parse(event.data);
      if (Array.isArray(newData)) {
        setCocina(newData);
      } else {
        console.error("Received non-array new data via SSE:", newData);
      }
    };

    eventSource.onerror = error => {
      console.error('EventSource failed:', error);
      eventSource.close();
    };

    return () => eventSource.close();
  }, [baseURL]);

  const deleteCocina = async (id) => {
    try {
      const response = await axios.delete(`${baseURL}/${id}`);
      if (response.status === 204) {
        setCocina(current => current.filter(factura => factura._id !== id));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const createCocina = async (factura) => {
    try {
      const response = await axios.post(baseURL, factura);
      setCocina(current => [...current, response.data]);
    } catch (error) {
      console.error(error);
    }
  };

  const getCocina = async (id) => {
    try {
      const response = await axios.get(`${baseURL}/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const updateCocina = async (id, factura) => {
    try {
      const response = await axios.put(`${baseURL}/${id}`, factura);
      setCocina(current => current.map(f => (f._id === id ? response.data : f)));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <CocinaContext.Provider value={{
      cocina,
      deleteCocina,
      createCocina,
      getCocina,
      updateCocina,
    }}>
      {children}
    </CocinaContext.Provider>
  );
};