import React from 'react';
import './Metricas.scss';

export function Metricas({ selectedColor }) {
  return (
    <div className="main-metricas">
      <div className="dashboard-container">
        <div className="dashboard-item total-revenue">
          <h2>Total Revenue</h2>
        </div>
        <div className="dashboard-item subscriptions">
          <h2>Subscriptions</h2>
        </div>
        <div className="dashboard-item calendar">
          <h2>Calendar</h2>
        </div>
        <div className="dashboard-item goals">
          <h2>Goals</h2>
        </div>
        <div className="dashboard-item team-members">
          <h2>Team Members</h2>
        </div>
        <div className="dashboard-item team-chat">
          <h2>Team Chat</h2>
        </div>
        <div className="dashboard-item exercise-minutes">
          <h2>Exercise Minutes</h2>
        </div>
      </div>
    </div>
  );
}